import React, { Component } from "react";
import _ from "lodash";
import "../interactiveChart.css";
import { Fragment } from "react";
import { CRUSTDATA_PLATFORM_URL } from "../environment";
// var Chart = require("chart.js"); // refer https://www.chartjs.org/docs/latest/
// import { Line } from "react-chartjs-2";
// Converted existing custdata media to use MDX:
// https://www.gatsbyjs.com/blog/2019-11-21-how-to-convert-an-existing-gatsby-blog-to-use-mdx/

var Chart = require("chart.js"); // refer https://www.chartjs.org/docs/latest/

export default class InteractiveChart extends Component {
  constructor(props) {
    super(props);
    this.chartCtx = null;
    this.state = {
      timeSeriesList: [],
      chart_data: {},
    };
    // this.getMonthFromNumber = this.getMonthFromNumber.bind(this);
  }
  componentDidMount = () => {
    import(`../../content/blog/${this.props.data.path}`).then((m) =>
      this.setState({ chart_data: m.default }, () => {})
    );
  };

  createChart() {
    this.chart = new Chart(this.chartCtx, {
      type: "line",
      data: {
        datasets: this.state.chart_data.datasets,
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "",
        },

        tooltips: {
          titleFontSize: 12,
          bodyFontSize: 12,
          xPadding: 10,
          yPadding: 10,

          mode: "nearest",
          intersect: false,
          callbacks: {
            title: function (tooltipItem, data) {
              return tooltipItem[0].xLabel;
            },
            label: function (tooltipItem, data) {
              const company_name = data.datasets.filter(
                (dataset_obj, index) => index === tooltipItem.datasetIndex
              )[0].label;
              return company_name + ": " + tooltipItem.yLabel;
            },
          },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: false,
                min: this.state.chart_data.datasets.reduce((accumulator, curr_dataset) => {
                  let curr_min = Math.min(...curr_dataset.data.map(obj => obj.y));
                  return curr_min < accumulator ? curr_min : accumulator;
                }, Number.POSITIVE_INFINITY),
                max: this.state.chart_data.datasets.reduce((accumulator, curr_dataset) => {
                  let curr_max = Math.max(...curr_dataset.data.map(obj => obj.y));
                  return curr_max > accumulator ? curr_max : accumulator;
                }, Number.NEGATIVE_INFINITY),
                maxTicksLimit: 2
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              type: "time",

              time: {
                unit: "month",
                unitStepSize: 1,
                tooltipFormat: "DD MMM YYYY",
              },
              ticks: {
                beginAtZero: false,
                maxTicksLimit: 1
              }
            },
          ],
        },
      },
    });
  }
  componentDidUpdate = () => {
    if (!_.isEmpty(this.state.chart_data)) {
      this.createChart();
    }
  };
  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(nextProps, this.props) === false) {
      return true;
    }
    if (_.isEqual(nextState, this.state)) {
      return false;
    } else if (_.isEqual(nextState, this.state) === false) {
      return true;
    }
  }
  render() {
    return (
      <Fragment>
        <h4>{this.state.chart_data.chart_title}</h4>
        <div className="chart-container">
          <canvas
            className={"chartview_canvas"}
            ref={(ctx) => (this.chartCtx = ctx)}
          />
        </div>
        <div>
          <a target="_blank" href={CRUSTDATA_PLATFORM_URL}>
            Source: Crustdata Alternative Data
          </a>
        </div>
      </Fragment>
    );
  }
}
