import React, { Component } from "react";
import "../interactiveMap.css";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
export class OverlayDetails extends Component {
  render() {
    return (
      <div>
        <span
          id="legend-markerColor"
          style={{ backgroundColor: `${this.props.color}` }}
        />
        <span className="legend-tickerName">
          {this.props.lower} - {this.props.upper}
        </span>
      </div>
    );
  }
}
export class LegendInfo extends Component {
  /**
   * This funciton is triggred when user click on legend list item.
   * every time user toggles the list item (li) this function will call onther function
   * defined in LegendInfo's parent component. It also sends comapany name which was toggled along with
   * its index number.
   */
  onMarkerClick = () => {
    this.props.onMarkerToggle(this.props.companyName, this.props.index);
  };
  render() {
    /** 
        *   this.props.status is the boolean value which determines the whether to show or hide the
      the particular company name. It is a part of parents's state.
       */
    return (
      <li
        onClick={this.onMarkerClick}
        id="legend-li"
        className={`Legend_item-${this.props.status}`}>
        <span
          id="legend-markerColor"
          style={{ backgroundColor: `${this.props.markerColor}` }}></span>
        <span className="legend-tickerName">{this.props.companyName}</span>
      </li>
    );
  }
}

const asc = (arr) => arr.sort((a, b) => a - b);

const quantile = (arr, q) => {
  const sorted = asc(arr);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

export const getQuantile = (list) => {
  let quantile_range = [0.25, 0.5, 0.75];
  let processed_values = [];
  for (let i = 0; i < 3; i++) {
    processed_values.push(quantile(list, quantile_range[i]).toFixed(2));
  }
  processed_values.push(Math.floor(Math.max(...list)));
  return processed_values;
};
// [
// {geometry: {type: "Point", coordinates: Array(2)}
// properties: {companyName: "Pizza Hut India"}}
// ]
export const PrettoSlider = withStyles({
  root: {
    color: "black",
    height: 8,
  },
  thumb: {
    display: "none",
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
export let getDummygeocode = () => {
  let dummy_geocode_array = [];
  let stringer;
  let length = 0;
  for (let i = 1; i <= 700; i++) {
    length = (Math.log(i) * Math.LOG10E + 1) | 0;
    if (length === 1) {
      stringer = "IN-00" + `${i}`;
    }
    if (length === 2) {
      stringer = "IN-0" + `${i}`;
    }
    if (length === 3) {
      stringer = "IN-" + `${i}`;
    }
    dummy_geocode_array.push(stringer);
  }
  return dummy_geocode_array;
};

// @flow
/**
 * Given a table, a dictionary with keys "fields" and "rows", this function returns the rows
 * containing the columns which match the criteria of as given in field_keys and field_values
 * @param table
 * @param field_keys
 * @param field_values
 * @returns {*|*[]}
 * Example : table = {"fields": [{"api_name": "col1", "type": "number"}, {"api_name": "col2", "type": "string"}, {"api_name": "col3", "type": "number"}]
 "rows": [[1,"2", 3],
          [4,"5", 6],
          [7,"8", 9]]
}
now if we want col1 and col 3 then we need to pass ['api_name', 'api_name'] as field_keys and [col1, col3] to field_value. 
 * 
 */
export const getRowsWithColumnsMatching = (table, field_keys, field_values) => {
  const column_indices = field_keys.reduce((acc, field_key, i) => {
    const column_index = table.fields.findIndex(
      (value) => value[field_key] === field_values[i]
    );
    if (column_index > -1 && !acc.includes(column_index)) {
      acc.push(column_index);
    }
    return acc;
  }, []);
  return table.rows.map((row) => column_indices.map((i) => row[i]));
};
