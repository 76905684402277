import * as React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link, graphql } from "gatsby";

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0.7;
`;

const FlexLi = styled.li`
  display: flex;
  // width: 70%;
  flex-direction: column;
  margin-bottom: 30px;
`;
const PostImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.3;
`;
export class RecommendationPanel extends React.Component {
  render() {
    return (
      <aside className="recommendation-panel">
        <div className="sidebar-content">
          <div className="recommended-content">
            <p className="recommended-head">Recommended</p>
            {this.props.recommendation.map((posts, i) => {
              if (posts.frontmatter.title !== this.props.current_post_title) {
                const image = getImage(posts.frontmatter.thumbnail);
                const title = posts.frontmatter.title || posts.fields.slug;
                return (
                  <FlexLi key={posts.fields.slug}>
                    <PostImage>
                      <GatsbyImage
                        imgStyle={{
                          "object-fit": "contain",
                          opacity: "1",
                        }}
                        image={image}
                        alt={posts.frontmatter.description}
                      />
                    </PostImage>
                    <Content>
                      <article
                        className="post-list"
                        itemScope
                        itemType="http://schema.org/Article">
                        <header>
                          <h5 className="post-list-heading">
                            <Link to={posts.fields.slug} itemProp="url">
                              <span itemProp="headline">{title}</span>
                            </Link>
                          </h5>
                          <small>{posts.frontmatter.date}</small>
                        </header>
                      </article>
                    </Content>
                  </FlexLi>
                );
              }
            })}
          </div>
        </div>
      </aside>
    );
  }
}

export default RecommendationPanel;
