import * as React from "react";
import { Link } from "gatsby";
import Banner from "../images/banner1.png";
import { CRUSTDATA_PLATFORM_URL } from "../environment.js";

export class PlatformBanner extends React.Component {
  render() {
    return (
      <div className="crustdata-platform-banner">
        <Link to="/">
          <img style={{ borderRadius: "10px" }} src={Banner} />
        </Link>
      </div>
    );
  }
}
export default PlatformBanner;
