import React, {Component, Fragment} from "react";
import _ from "lodash";
import "../interactiveChart.css";
import {CRUSTDATA_PLATFORM_URL} from "../environment";
// var Chart = require("chart.js"); // refer https://www.chartjs.org/docs/latest/
// import { Line } from "react-chartjs-2";
// Converted existing custdata media to use MDX:
// https://www.gatsbyjs.com/blog/2019-11-21-how-to-convert-an-existing-gatsby-blog-to-use-mdx/

var Chart = require("chart.js"); // refer https://www.chartjs.org/docs/latest/

export default class InteractiveMutliAxisChart extends Component {
  constructor(props) {
    super(props);
    this.chartCtx = null;
    this.state = {
      timeSeriesList: [],
      chart_data: {},
    };
  }
  componentDidMount = () => {
    import(`../../content/blog/${this.props.data.path}`).then((m) =>
      this.setState({ chart_data: m.default }, () => {})
    );
  };
  componentDidUpdate = () => {
    if (!_.isEmpty(this.state.chart_data)) {
      this.createChart(true);
    }
  };
  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(nextProps, this.props) === false) {
      return true;
    }
    if (_.isEqual(nextState, this.state)) {
      return false;
    } else if (_.isEqual(nextState, this.state) === false) {
      return true;
    }
  }
  createChart() {
    this.chart = new Chart(this.chartCtx, {
      type: "line",
      data: {
        datasets: this.state.chart_data.datasets,
      },
      options: {
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        tooltips: {
          mode: "nearest",
          intersect: false,
          titleFontSize: 12,
          bodyFontSize: 12,
          xPadding: 10,
          yPadding: 10,
          callbacks: {
            label: (tooltipItem, data) => {
              var value = parseFloat(tooltipItem.value);
              var formatedValue;
              if (tooltipItem.datasetIndex > 0) {
                if (
                  this.state.chart_data.hasOwnProperty(
                    "right_yaxis_lable_prefix"
                  )
                ) {
                  formatedValue =
                    this.state.chart_data.right_yaxis_lable_prefix + value;
                } else {
                  formatedValue = "  " + value;
                }
              } else {
                formatedValue = "  " + value;
              }
              return formatedValue;
            },
          },
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              stacked: true,
              type: "time",
              time: {
                unit: "month",
                tooltipFormat: "DD MMM YYYY",
              },
              ticks: {
                beginAtZero: false,
                maxTicksLimit: 1
              }
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              position: "left",
              type: "linear",
              scaleLabel: {
                display: true,
              },
              id: "y-axis-0",
              ticks: {
                beginAtZero: false,
                callback: function (tick, index, ticks) {
                  return tick;
                },
                // Get the minimum value of "y" axis for all the data elements from datasets which have y-axis as "y-axis-0"
                /**
                 * Example data:
                 this.state.chart_data = {
                    "chart_title": "NSE:JUBLFOOD Stock Price and # of Stores",
                    "right_yaxis_lable_prefix": "₹",
                    "yAxisID": "y-axis-0",
                    "datasets": [
                      {
                        "type": "line",
                        "lineTension": 0,
                        "label": "# Stores",
                        "data": [{ "x": "2020-05-08T00:00:00.000Z", "y": 1298 },
                                 { "x": "2020-05-09T00:00:00.000Z", "y": 1298 }, ...]
                        ...
                      }, ...]
                 }
                 */
                min: this.state.chart_data.datasets.reduce((accumulator, curr_dataset) => {
                  if (curr_dataset.yAxisID === "y-axis-0") {
                    let curr_min = Math.min(...curr_dataset.data.map(obj => obj.y));
                    return curr_min < accumulator ? curr_min : accumulator;
                  }
                  return accumulator;
                }, Number.POSITIVE_INFINITY),
                max: this.state.chart_data.datasets.reduce((accumulator, curr_dataset) => {
                  if (curr_dataset.yAxisID === "y-axis-0") {
                    let curr_max = Math.max(...curr_dataset.data.map(obj => obj.y));
                    return curr_max > accumulator ? curr_max : accumulator;
                  }
                  return accumulator;
                }, Number.NEGATIVE_INFINITY),
                maxTicksLimit: 2
              },
            },
            {
              gridLines: {
                display: false,
              },
              stacked: true,
              position: "right",
              type: "linear",
              scaleLabel: {
                display: true,
              },
              id: "y-axis-1",
              ticks: {
                beginAtZero: false,
                callback: (tick, index, ticks) => {
                  if (
                    this.state.chart_data.hasOwnProperty(
                      "right_yaxis_lable_prefix"
                    )
                  ) {
                    return (
                      this.state.chart_data.right_yaxis_lable_prefix + tick
                    );
                  }

                  return tick;
                },
                min: this.state.chart_data.datasets.reduce((accumulator, curr_dataset) => {
                  if (curr_dataset.yAxisID === "y-axis-1") {
                    let curr_min = Math.min(...curr_dataset.data.map(obj => obj.y));
                    return curr_min < accumulator ? curr_min : accumulator;
                  }
                  return accumulator;
                }, Number.POSITIVE_INFINITY),
                max: this.state.chart_data.datasets.reduce((accumulator, curr_dataset) => {
                  if (curr_dataset.yAxisID === "y-axis-1") {
                    let curr_max = Math.max(...curr_dataset.data.map(obj => obj.y));
                    return curr_max > accumulator ? curr_max : accumulator;
                  }
                  return accumulator;
                }, Number.NEGATIVE_INFINITY),
                maxTicksLimit: 2
              },
            },
          ],
        },
      },
    });
  }

  render() {
    return (
      <Fragment>
        <h4>{this.state.chart_data.chart_title}</h4>
        <div className="chart-container">
          <canvas
            className={"chartview_canvas"}
            ref={(ctx) => (this.chartCtx = ctx)}
          />
        </div>
        <div>
          <a target="_blank" href={CRUSTDATA_PLATFORM_URL}>
            Source: Crustdata Alternative Data
          </a>
        </div>
      </Fragment>
    );
  }
}
