import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
import "../interactiveMap.css";
import _ from "lodash";
import {
  MAPBOX_TOKEN,
  OVERLAY_COLORS,
  CRUSTDATA_PLATFORM_URL,
  TILESET_LINE_COLOR,
  TILESET_SOURCE_LAYER_ID,
  TILESET_URL,
  OVERLAY_MAP_STYLE,
} from "../environment";
import styled from "styled-components";

import {
  getQuantile,
  getRowsWithColumnsMatching,
} from "./interactiveMapHelper";
import ReactDOM from "react-dom";
mapboxgl.accessToken = MAPBOX_TOKEN;
const MapContainer = styled.div`
  display: flex;
  height: 82%;
  width: 100%;
  flex-direction: column;
  margin-top: 30px;
  flex-flow: wrap;
`;

/**
 * Downgraded mapbox-gli from 2.2.0 to 1.13 to fix display issue when site is served from the server.
 https:stackoverflow.com/questions/65394014/how-to-make-mapbox-load-on-gatsby-site-build-succeeds-but-map-not-displaying-d#
 * 
 */

export default class InteractiveChoroplethMap extends Component {
  // https://stackoverflow.com/questions/59542854/typeerror-mapboxgl-default-map-is-not-a-constructor
  map;
  randomId;
  tickerNames = [];
  constructor(props) {
    super(props);
    /**
     * hiddenCompanyName: it is a list which contains all the company names which are hidden by user
     * by pressing toggle button in legend.
     *
     * legendInfo: it is a list of objects. Each object has  3 keys i.e key, value, status. status will
     * decide whether company name is hidden or not. iteration is made over legendInfo to generate LegendInfo
     * component.
     */
    this.state = {
      lng: 78.723,
      lat: 21.41,
      zoom: 3.55,
      key: 0,
      overlay_legend_data: [],
      row_object: {},
      value_title: "",
      map_data: {},
    };
  }

  componentDidMount() {
    import(`../../content/blog/${this.props.data.path}`).then((m) =>
      this.setState({ map_data: m.default })
    );
    this.randomId = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    let mounted_id = document.getElementById("mapContainer");
    if (mounted_id) {
      mounted_id.setAttribute("id", this.randomId);
    }

    this.map = new mapboxgl.Map({
      container: this.randomId,
      // Added ?optimize=true to make map load fast but remove it if it messes things up while rendering data on the fly
      style: OVERLAY_MAP_STYLE,
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
      preserveDrawingBuffer: true,
    });

    this.map.on("move", () => {
      this.setState({
        lng: this.map.getCenter().lng.toFixed(4),
        lat: this.map.getCenter().lat.toFixed(4),
        zoom: this.map.getZoom().toFixed(2),
      });
    });

    //  ://stackoverflow.com/questions/42483449/mapbox-gl-js-export-map-to-png-or-pdf
    var dpi = 300;
    Object.defineProperty(window, "devicePixelRatio", {
      get: function () {
        return dpi / 96;
      },
    });

    // this.map.boxZoom.disable();
    this.map.dragRotate.disable();
    this.map.setMaxZoom(12);
    this.map.touchZoomRotate.disableRotation();

    this.map.on("load", () => {
      this.map.resize();
    });
  }
  componentDidUpdate = () => {
    if (!_.isEmpty(this.state.map_data)) {
      let { value_title, row_object } = this.getRowObject();
      this.setState({ row_object, value_title });

      this.map.on("load", () => {
        this.addOverlay();

        this.map.resize();
      });
    }
  };
  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(nextProps, this.props) === false) {
      return true;
    }
    if (_.isEqual(nextState, this.state)) {
      return false;
    } else if (_.isEqual(nextState, this.state) === false) {
      return true;
    }
  }
  getRowObject = () => {
    let index_of_geocode = this.state.map_data.overlay.fields.findIndex(
      (ele) => ele.geocode === true
    );
    let index_of_value = this.state.map_data.overlay.fields.findIndex(
      (ele) => ele.type === "number"
    );
    let api_name_of_value = this.state.map_data.overlay.fields[index_of_value]
      .api_name;

    let formatted_api_name = api_name_of_value.replace(/[^a-zA-Z ]/g, " ");
    let row_object = this.state.map_data.overlay.rows.reduce((acc, cur) => {
      acc[cur[index_of_geocode]] = cur[index_of_value];
      return acc;
    }, {});
    return {
      value_title: _.startCase(_.toLower(formatted_api_name)),
      row_object,
    };
  };

  addOverlay() {
    if (this.map && this.state.map_data.overlay) {
      var mapLayer = this.map.getLayer("tileset-bountary");
      if (typeof mapLayer !== "undefined") {
        // Remove map layer & source.
        this.map.removeLayer("tileset-bountary").removeSource("states");
        this.map.removeLayer("tileset").removeSource("states");
      }
      let { rows, fields } = this.state.map_data.overlay;
      const first_quantile_color = OVERLAY_COLORS[0];
      const second_quantile_color = OVERLAY_COLORS[1];
      const third_quantile_color = OVERLAY_COLORS[2];
      const forth_quantile_color = OVERLAY_COLORS[3];
      let index_of_type_number = this.state.map_data.overlay.fields.findIndex(
        (field) => field.type === "number"
      );
      let localdata_list = rows.map((row) => row[index_of_type_number]);
      let quantile_value = getQuantile(localdata_list);
      this.map.addSource("states", {
        type: "vector",
        url: TILESET_URL,
      });

      // mapbox expects the data in such format
      var mapbox_geocode_color_list = ["match", ["get", "geocode"]];

      //  get filterd colums
      let map_data_rows = getRowsWithColumnsMatching(
        this.state.map_data.overlay,
        ["type", "geocode"],
        ["number", true]
      );

      //   Assign quantile color to each geocode
      map_data_rows.forEach(function (row) {
        let metric_value = row[0];
        let geocode = row[1];
        if (geocode) {
          if (metric_value <= quantile_value[0]) {
            mapbox_geocode_color_list.push(geocode, first_quantile_color);
          } else if (
            metric_value <= quantile_value[1] &&
            metric_value > quantile_value[0]
          ) {
            mapbox_geocode_color_list.push(geocode, second_quantile_color);
          } else if (
            metric_value <= quantile_value[2] &&
            metric_value > quantile_value[1]
          ) {
            mapbox_geocode_color_list.push(geocode, third_quantile_color);
          } else if (
            metric_value <= quantile_value[3] &&
            metric_value > quantile_value[2]
          ) {
            mapbox_geocode_color_list.push(geocode, forth_quantile_color);
          }
        }
      });

      // Last value is the default, used where there is no data
      mapbox_geocode_color_list.push("rgba(0,0,0,0)");
      // Add layer from the vector tile source with data-driven style
      this.map.addLayer({
        id: "tileset",
        type: "line",
        source: "states",
        "source-layer": TILESET_SOURCE_LAYER_ID,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": TILESET_LINE_COLOR,
          "line-width": 0.5,
        },
      });
      this.map.addLayer(
        {
          id: "tileset-bountary",
          type: "fill",
          source: "states",
          "source-layer": TILESET_SOURCE_LAYER_ID,
          paint: {
            "fill-color": mapbox_geocode_color_list,
          },
        },
        "waterway-label"
      );

      //   generate a popup on state-layer (layer id) when ever vector layer is clicked
      this.map.on("click", "tileset-bountary", (e) => {
        let info = this.state.row_object;

        if (info[e.features[0].properties.geocode]) {
          this.addPopup(
            <MapPopup
              value_title={this.state.value_title}
              name={e.features[0].properties.name}
              geocode={e.features[0].properties.geocode}
              value={info[e.features[0].properties.geocode]}
            />,
            e.lngLat.lat,
            e.lngLat.lng
          );
        }
      });

      // Change the cursor to a pointer when the mouse is over the states layer.
      this.map.on("mouseenter", "tileset-bountary", () => {
        this.map.getCanvas().style.cursor = "pointer";
      });

      // Change it back to a pointer when it leaves.
      this.map.on("mouseleave", "tileset-bountary", () => {
        this.map.getCanvas().style.cursor = "";
      });

      //   Generates overlay legend
      let overlay_details = this.getOverlayLegend(
        localdata_list,
        quantile_value
      );
      this.setState({ overlay_legend_data: overlay_details });
    }
  }
  addPopup(el, lat, lng) {
    const placeholder = document.createElement("div");
    ReactDOM.render(el, placeholder);

    const marker = new mapboxgl.Popup({ closeButton: false })
      .setDOMContent(placeholder)
      .setLngLat({ lng: lng, lat: lat })
      .addTo(this.map);
  }
  getOverlayLegend(localdata_list, quantile_value) {
    let overlay_details = [];
    let min_value = Math.min(...localdata_list);
    for (let i = 0; i < quantile_value.length; i++) {
      if (i === 0) {
        overlay_details.push([
          Math.round(min_value, 2),
          quantile_value[i],
          OVERLAY_COLORS[i],
        ]);
      } else {
        overlay_details.push([
          quantile_value[i - 1],
          quantile_value[i],
          OVERLAY_COLORS[i],
        ]);
      }
    }
    return overlay_details;
  }

  render() {
    let mapbox_id = this.randomId ? this.randomId : "mapContainer";
    return (
      <div className="interactiveMap-container">
        <div>
          <h5>
            {this.state.map_data ? this.state.map_data.map_title : "Mapbox Map"}
          </h5>
          <div id="Legend">
            {this.state.overlay_legend_data.map((ele, i) => {
              return (
                <OverlayDetails
                  key={i}
                  color={ele[2]}
                  lower={ele[0]}
                  upper={ele[1]}
                />
              );
            })}
          </div>
        </div>
        <MapContainer className="MapView" id={mapbox_id} />
        <div>
          <a target="_blank" href={CRUSTDATA_PLATFORM_URL}>
            Source: Crustdata Alternative Data
          </a>
        </div>
      </div>
    );
  }
}

export class OverlayDetails extends Component {
  render() {
    return (
      <div style={{ marginRight: "20px" }}>
        <span
          id="legend-markerColor"
          style={{ backgroundColor: `${this.props.color}` }}
        />
        <span className="legend-tickerName">
          {this.props.lower} - {this.props.upper}
        </span>
      </div>
    );
  }
}

export class MapPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="mappopup-container">
        <p className="district">{this.props.name}</p>
        <hr />
        {this.props.value && (
          <p className="value">
            <span>{this.props.value_title}: </span>
            {this.props.value.toFixed(2)}
          </p>
        )}
      </div>
    );
  }
}
