import { useWindowScroll } from "react-use";
import React, { useState, useEffect } from "react";
import "../progress_bar.css";
function ProgressBar(props) {
  const { x, y } = useWindowScroll();
  const [scrolled, setScrolled] = useState(0);
  useEffect(() => {
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    setScrolled((y / height) * 100);
  }, [y]);
  return (
    <div
      className={
        props.view === "desktop"
          ? "scroll-container"
          : "mobile-scroll-container"
      }>
      <div className="indicator" style={{ width: `${scrolled}%` }}></div>
    </div>
  );
}

export default ProgressBar;
