import React, { Component } from "react";
import _, { negate } from "lodash";
import "../interactiveChart.css";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import Button from "@material-ui/core/Button";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
const ITEM_HEIGHT = 48;
const options = ["1x", "2x", "3x"];
const PrettoSlider = withStyles({
  root: {
    color: "black",
    height: 8,
  },
  thumb: {
    display: "none",
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
const useStyles = (theme) => ({
  root: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
  },
  margin: {
    height: theme.spacing(3),
  },
});

var Chart = require("chart.js"); // refer https://www.chartjs.org/docs/latest/

// Converted existing custdata media to use MDX:
// https://www.gatsbyjs.com/blog/2019-11-21-how-to-convert-an-existing-gatsby-blog-to-use-mdx/

var Chart = require("chart.js"); // refer https://www.chartjs.org/docs/latest/
class InteractiveBarChart extends Component {
  constructor(props) {
    super(props);
    this.chartCtx = null;
    this.state = {
      slider_value: null,
      slider_markers: [],
      slider_info: {},
      play_status: false,
      label_and_colors: {},
      chart_data: {},
    };
    // this.getMonthFromNumber = this.getMonthFromNumber.bind(this);
  }

  getRandomColor = () => {
    let color =
      "rgba(" +
      Math.ceil(Math.random() * 360) +
      "," +
      Math.ceil(Math.random() * 360) +
      "," +
      Math.ceil(Math.random() * 360) +
      ",0.2)";
    // ("rgba(255, 99, 132, 0.2)");
    return color;
  };

  getSliderInfo = (data) => {
    data.sort(function (a, b) {
      return a.value - b.value;
    });

    var min = data[0],
      max = data[data.length - 1];
    return { min: min.value, max: max.value };
  };
  // https://blog.bitsrc.io/customizing-chart-js-in-react-2199fa81530a
  componentDidMount = () => {
    import(`../../content/blog/${this.props.data.path}`).then((m) =>
      this.setState({ chart_data: m.default })
    );
    // Basically we create an instance of chart when this component is mounted and store it in a class variable.
    // The interesting thing is, the context passed (this.chartCtx) is still null at this time and that is okay
    // because this chart object will not be rendered until the `render()` is called for the first time. And when
    // it is actually called, we set a callback ref in rendered canvas element which actually sets the
    // context (`this.chartCtx`) used in the chart object
    Chart.defaults.LineWithLine = Chart.defaults.line;
    Chart.controllers.LineWithLine = Chart.controllers.line.extend({
      draw: function (ease) {
        Chart.controllers.line.prototype.draw.call(this, ease);

        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
          var activePoint = this.chart.tooltip._active[0],
            ctx = this.chart.ctx,
            x = activePoint.tooltipPosition().x,
            topY = this.chart.legend.bottom,
            bottomY = this.chart.chartArea.bottom;

          // draw line
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 2;
          ctx.strokeStyle = "#89918b";
          ctx.stroke();
          ctx.restore();
        }
      },
    });
    this.createChart();
  };
  componentDidUpdate = () => {
    if (!_.isEmpty(this.state.chart_data)) {
      this.createChart();
    }
  };
  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(nextState.chart_data, this.state.chart_data)) {
      return false;
    } else if (
      _.isEqual(nextState.chart_data, this.state.chart_data) === false
    ) {
      return true;
    }
  }
  /**
   *
   * @param {event} event
   * @param {string} newValue
   *
   * funtion is called when slider is moved. This funtion updates the bar chart with the date to which silder is moved.
   */
  handleChange = (event, newValue) => {
    let {
      label,
      dataset,
      background_colors,
      label_and_colors,
    } = this.getBarDataset(newValue);

    let updated_labels_and_colors = {
      ...this.state.label_and_colors,
      ...label_and_colors,
    };
    this.chart.data.labels = label;
    this.chart.data.datasets = [
      {
        data: dataset,
        backgroundColor: background_colors,
      },
    ];
    this.setState(
      { slider_value: newValue, label_and_colors: updated_labels_and_colors },
      () => {
        this.chart.update();
      }
    );
  };

  /**
   * this funtion creates markes for slider component.
   * Example of the return value:
   * [{value: 201707, label: "201707"},{value: 201807, label: "201807"}, {value: 201907, label: "201907"}]
   *
   */
  getMarkers = () => {
    let marker = [];
    let i = 0;
    for (const [key, value] of Object.entries(this.state.chart_data.datasets)) {
      marker.push({ value: parseInt(key), label: key });
      i++;
    }
    // Here 4 is the marker limit
    let evenly_distributed_marker_list = this.getEvenlyDistributedList(
      marker,
      4
    );
    return evenly_distributed_marker_list;
  };
  createChart() {
    if (!_.isEmpty(this.state.chart_data)) {
      let newValue = parseInt(Object.keys(this.state.chart_data.datasets)[0]);
      let {
        label,
        dataset,
        background_colors,
        label_and_colors,
      } = this.getBarDataset(newValue);

      let data = {
        labels: label,
        datasets: [
          {
            label: this.state.chart_data.chart_title,
            data: dataset,
            backgroundColor: background_colors,
          },
        ],
      };

      this.chart = new Chart(this.chartCtx, {
        type: "horizontalBar",
        data: data,
        // https://stackoverflow.com/questions/38645829/chart-js-writing-labels-inside-of-horizontal-bars#:~:text=I%20did%20this%20by%20inspecting,them%20for%20the%20fillText%20method.&text=You%20can%20achieve%20this%20effect,up%20inside%20the%20'bar'.
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                // ticks prop is used to put y-axes labels inside each bar
                ticks: { mirror: true },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
      });

      let marker = this.getMarkers();
      let slider_info = this.getSliderInfo(marker);
      this.setState({
        label_and_colors: label_and_colors,
        slider_markers: marker,
        slider_info: slider_info,
        slider_value: parseInt(Object.keys(this.state.chart_data.datasets)[0]),
      });
    }
  }

  /**
   *
   * @param {List} list
   * @param {Number} limit_num
   * this method will take array of length m and returns evenly distributed array of lengh limit_num
   */
  getEvenlyDistributedList(list, limit_num) {
    let len = parseFloat(list.length);
    let evenly_distributed_list = [];
    for (let i = 0; i < limit_num; i++) {
      evenly_distributed_list.push(
        list[parseInt(Math.ceil((i * len) / limit_num))]
      );
    }
    return evenly_distributed_list;
  }

  /**
   * This funtion updates the playing time line. It is called every one second by setInterval() method.
   * It iterates over the this.state.chart_data.datasets and keep on updateing the bar chart with new data.
   */
  updateChart = () => {
    let toggle = false;
    let new_slider_value = null;
    for (const [key, value] of Object.entries(this.state.chart_data.datasets)) {
      if (toggle) {
        new_slider_value = parseInt(key);
        break;
      }
      if (parseInt(key) === this.state.slider_value) {
        toggle = true;
      }
    }

    let {
      label,
      dataset,
      background_colors,
      label_and_colors,
    } = this.getBarDataset(new_slider_value);

    // this is done to append the existing object (containing labels and there respective colors) with new labels.
    let updated_labels_and_colors = {
      ...this.state.label_and_colors,
      ...label_and_colors,
    };
    this.chart.data.labels = label;
    this.chart.data.datasets = [
      {
        data: dataset,
        backgroundColor: background_colors,
      },
    ];
    this.chart.update();
    //when time line slider reaches the latest date this conditional statement terminals the loop created by setInterval() using clearInteraval() method.
    if (new_slider_value === this.state.slider_info.max) {
      clearInterval(this.interval);
      this.setState({
        slider_value: new_slider_value,
        play_status: false,
        label_and_colors: updated_labels_and_colors,
      });
      return;
    }
    this.setState({
      slider_value: new_slider_value,
      play_status: true,
      label_and_colors: updated_labels_and_colors,
    });
  };
  /**
   Exectuted when play button is press to start the time line.
 * 
 */
  onPlay = () => {
    // when slider reaches the latest date, this conditional statement is executed. It set the slider_value back to the oldest date.
    if (this.state.slider_value === this.state.slider_info.max) {
      this.setState((prevState) => ({
        slider_value: prevState.slider_info.min,
      }));
    }
    // https://medium.com/@staceyzander/setinterval-and-clearinterval-in-react-b1d0ee1e1a6a
    this.interval = setInterval(this.updateChart, 2000);
  };

  /**
 Executed when pause button is press
 * 
 */
  onPause = () => {
    clearInterval(this.interval);
    this.setState({ play_status: false });
  };

  /**
   *
   * @param {Number} new_date
   *This funtion is called to get data for new_date.
   * Interactive_bar_chart_data is an object which has date as KEY and list of labels and value as VALUE
   */
  getBarDataset(new_date) {
    let label = [];
    let dataset = [];
    let background_colors = [];
    let random_color = null;
    let label_and_colors = {};
    for (const [key, value] of Object.entries(this.state.chart_data.datasets)) {
      if (new_date === parseInt(key)) {
        value.forEach((ele) => {
          // this is done to store colors associated to a label, so that new color is not
          // associated to bars each time chart is updated
          if (this.state.label_and_colors[ele[0]] === undefined) {
            random_color = this.getRandomColor();
            label_and_colors[ele[0]] = random_color;
            background_colors.push(random_color);
          } else {
            background_colors.push(this.state.label_and_colors[ele[0]]);
          }
          label.push(ele[0]);
          dataset.push(ele[1]);
        });
      }
    }
    return { label, dataset, background_colors, label_and_colors };
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <canvas ref={(ctx) => (this.chartCtx = ctx)} />
        <div className={classes.root}>
          <div className="play-button">
            {this.state.play_status ? (
              <Button onClick={this.onPause}>
                <PauseCircleFilledIcon fontSize="large" />
              </Button>
            ) : (
              <Button onClick={this.onPlay}>
                <PlayCircleFilledIcon fontSize="large" />
              </Button>
            )}
          </div>

          <div className="slider">
            <PrettoSlider
              min={this.state.slider_info.min}
              max={this.state.slider_info.max}
              value={this.state.slider_value}
              step={null}
              aria-labelledby="discrete-slider-always"
              onChange={this.handleChange}
              marks={this.state.slider_markers}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(InteractiveBarChart);
