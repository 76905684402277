import * as React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import InteractiveChart from "../components/interactiveChart";
import InteractiveMap from "../components/interactiveMap";
import InteractiveTilesetMap from "../components/interactiveTilesetMap";
import InteractiveTilesetTimeSliderMap from "../components/interactiveTilesetTimeseries";
import InteractiveBarChart from "../components/InteractiveBarChart";
import InteractiveTimeSliderMap from "../components/interactiveTimeSliderMap";
import InteractiveMutliAxisChart from "../components/interactiveMutliAxisChart";
import InteractiveChoroplethMap from "../components/interactiveChoroplethMap";
import InteractiveTable from "../components/tables";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import "../blog_post.css";
import Avatar from "@material-ui/core/Avatar";
import _ from "lodash";
import Img from "gatsby-image";
import PlatformBanner from "./platform-banner";
import RecommendationPanel from "./recommendation-panel";
import { makeStyles } from "@material-ui/core/styles";
import { Profiler } from "react";
// import mixpanel from "mixpanel-browser";
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');
import { useMediaQuery } from "react-responsive";
import ProgressBar from "../components/progress_bar";
// import Avatar from "@material-ui/core/Avatar";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import MailchimpSubscribe from "react-mailchimp-subscribe";
const url =
  "https://crustdata.us6.list-manage.com/subscribe/post?u=0d4bd3858165c768835e45b7f&amp;id=1dc605eed6";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  orange: {
    width: "32px",
    height: "30px",
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));
const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0.7;
`;

const FlexLi = styled.li`
  display: flex;
  // width: 70%;
  flex-direction: column;
  margin-bottom: 30px;
`;
const PostImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.3;
`;

const BlogPostTemplate = ({ data, location }) => {
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1400 });
    return isDesktop ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1400 });
    return isMobile ? children : null;
  };
  const classes = useStyles();
  const post = data.mdx;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const recommendation = data.allMdx.nodes.filter(
    (post_item) =>
      post_item.frontmatter.categories === post.frontmatter.categories
  );
  const thumbnail = post.frontmatter.thumbnail;
  const image = getImage(post.frontmatter.thumbnail);
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        thumbnail={thumbnail}
        description={post.frontmatter.description || post.excerpt}
        postMetaData={
          post.frontmatter.postMetaData || "Crustdata alternative data"
        }
      />
      <Desktop>
        <ProgressBar view="desktop" />

        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article">
          <div className="top-section">
            <div className="post-hero">
              <Img fluid={post.frontmatter.thumbnail.childImageSharp.fluid} />
              <div className="tag-strip">
                <div className="badge-container">
                  <a className="badge-anchor">
                    <span className="badge">
                      {_.upperCase(post.frontmatter.categories)}
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="post-headline">
              <h1 itemProp="headline">{post.frontmatter.title}</h1>
              <p className="post-description">{post.frontmatter.description}</p>
              <div className="post-details">
                <div className="author-details">
                  <Avatar className={classes.orange}>
                    {post.frontmatter.author[0]}
                  </Avatar>
                  <p className="auther-name">{post.frontmatter.author}</p>
                </div>
                {/* <div className="social-links">
                <LinkedInIcon fontSize="large" />
                <TwitterIcon fontSize="large" />
                <LinkIcon fontSize="large" />
              </div> */}
                <div className="post-data">
                  <p className="date">{post.frontmatter.date}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="main-section">
            <TagPanel tags={post.frontmatter.tags} />
            <div className="post-body">
              <Profiler id="Interatives">
                <MDXProvider
                  components={{
                    // https://www.gatsbyjs.com/docs/how-to/routing/customizing-components/
                    img: (props) => (
                      <img {...props} style={{ padding: "10px" }} />
                    ),
                    InteractiveChoroplethMap,
                    InteractiveChart,
                    InteractiveMap,
                    InteractiveTilesetMap,
                    InteractiveTilesetTimeSliderMap,
                    InteractiveBarChart,
                    InteractiveTimeSliderMap,
                    InteractiveTable,
                    InteractiveMutliAxisChart,
                  }}>
                  <MDXRenderer>{post.body}</MDXRenderer>
                </MDXProvider>
              </Profiler>
              <div className="blog-newsletter">
                <h5> SIGNUP FOR OUR NEWSLETTER</h5>
                {/* <p>
            Did you find this article useful? Then you might like to sign up to
            the newsletter!
          </p> */}
                <div className="blog-newsletter-form">
                  <MailchimpSubscribe url={url} />
                </div>
              </div>
            </div>

            <RecommendationPanel
              current_post_title={post.frontmatter.title}
              recommendation={recommendation}
            />
          </div>
        </article>
        {/* <PlatformBanner /> */}

        <ReferenceArticles data={data.allMdx.nodes} />
      </Desktop>
      <Mobile>
        <ProgressBar view="mobile" />

        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article">
          <div className="mobile-top-section">
            <div className="mobile-post-data">
              <p className="date">{post.frontmatter.date}</p>
              <p className={`date ${post.frontmatter.categories}`}>
                {_.upperCase(post.frontmatter.categories)}
              </p>
            </div>
            <div className="post-headline">
              <h1 className="mobile-headline">{post.frontmatter.title}</h1>
              <p className="mobile-post-description">
                {post.frontmatter.description}
              </p>
              <div className="mobile-post-details">
                <div className="author-details">
                  <Avatar className={classes.orange}>
                    {post.frontmatter.author[0]}
                  </Avatar>
                  <p className="auther-name">{post.frontmatter.author}</p>
                </div>
              </div>
            </div>
            <div className="mobile-post-hero">
              <Img fluid={post.frontmatter.thumbnail.childImageSharp.fluid} />
            </div>
          </div>

          <div className="mobile-main-section">
            <div className="mobilePB post-body">
              <MDXProvider
                components={{
                  // https://www.gatsbyjs.com/docs/how-to/routing/customizing-components/
                  img: (props) => (
                    <img {...props} style={{ padding: "10px" }} />
                  ),
                  InteractiveChoroplethMap,
                  InteractiveChart,
                  InteractiveMap,
                  InteractiveTilesetMap,
                  InteractiveTilesetTimeSliderMap,
                  InteractiveBarChart,
                  InteractiveTimeSliderMap,
                  InteractiveTable,
                  InteractiveMutliAxisChart,
                }}>
                <MDXRenderer>{post.body}</MDXRenderer>
              </MDXProvider>
              <div style={{ width: "98%" }} className="blog-newsletter">
                <h5> SIGNUP FOR OUR NEWSLETTER</h5>
                {/* <p>
            Did you find this article useful? Then you might like to sign up to
            the newsletter!
          </p> */}
                <div className="blog-newsletter-form">
                  <MailchimpSubscribe url={url} />
                </div>
              </div>
            </div>
          </div>
        </article>
        <ReferenceArticles data={data.allMdx.nodes} />
      </Mobile>
    </Layout>
  );
};

export class TagPanel extends React.Component {
  render() {
    return (
      <aside className="tag-panel">
        <ul className="tag-container">
          {this.props.tags &&
            this.props.tags.map((tag) => {
              return (
                <Link className="tag-link" to={`/tags/${tag}`}>
                  <li className="tag">{tag}</li>
                </Link>
              );
            })}
        </ul>
      </aside>
    );
  }
}

const ReferenceArticles = ({ data }) => {
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1400 });
    return isDesktop ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1400 });
    return isMobile ? children : null;
  };
  return (
    <div className="reference-artilces">
      <Desktop>
        <div className="main-articles-container">
          <h5 className="latest-articles-heading">Latest Articles</h5>
          <ol className="post-ordered-list" style={{ listStyle: `none` }}>
            {data.map((post, i) => {
              const image = getImage(post.frontmatter.thumbnail);
              const title = post.frontmatter.title || post.fields.slug;

              return (
                <li className="post-flex-list-index" key={post.fields.slug}>
                  <div className="post-thumbnail">
                    <a
                      className={`category-badge ${post.frontmatter.categories}`}>
                      <span>{_.upperCase(post.frontmatter.categories)}</span>
                    </a>

                    {/* <img src={post.frontmatter.thumbnail.publicURL} /> */}
                    <GatsbyImage
                      // className="index-thumbnail"
                      image={image}
                      imgStyle={{
                        objectFit: "contain",
                        opacity: "1",
                      }}
                      alt={post.frontmatter.description}
                    />
                  </div>
                  <div className="post-content">
                    <article
                      className="post-list-item"
                      itemScope
                      itemType="http://schema.org/Article">
                      <header>
                        <h2>
                          <Link to={post.fields.slug} itemProp="url">
                            <span className="post-heading" itemProp="headline">
                              {title}
                            </span>
                          </Link>
                        </h2>
                        <small>{post.frontmatter.date}</small>
                      </header>
                      <section>
                        <p
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html:
                              post.frontmatter.description || post.excerpt,
                          }}
                          itemProp="description"
                        />
                      </section>
                    </article>
                  </div>
                </li>
              );
            })}
          </ol>
          <div className="view-all-">
            <Link className="nav-link" to="/posts">
              View All
            </Link>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="mobile-main-container">
          <h5 className="latest-articles-heading">Latest Articles</h5>
          <ol className="post-ordered-list" style={{ listStyle: `none` }}>
            {data.map((post, i) => {
              const image = getImage(post.frontmatter.thumbnail);
              const title = post.frontmatter.title || post.fields.slug;

              return (
                <li
                  className="mobile-post-flex-list-index"
                  key={post.fields.slug}>
                  <div className="post-thumbnail">
                    <a
                      className={`category-badge ${post.frontmatter.categories}`}>
                      <span>{_.upperCase(post.frontmatter.categories)}</span>
                    </a>

                    {/* <img src={post.frontmatter.thumbnail.publicURL} /> */}
                    <GatsbyImage
                      // className="index-thumbnail"
                      image={image}
                      imgStyle={{
                        "object-fit": "contain",
                        opacity: "1",
                      }}
                      alt={post.frontmatter.description}
                    />
                  </div>
                  <div className="mobile-post-content">
                    <article
                      className="post-list-item"
                      itemScope
                      itemType="http://schema.org/Article">
                      <header>
                        <h2>
                          <Link to={post.fields.slug} itemProp="url">
                            <span className="post-heading" itemProp="headline">
                              {title}
                            </span>
                          </Link>
                        </h2>
                        <small>{post.frontmatter.date}</small>
                      </header>
                      <section>
                        <p
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html:
                              post.frontmatter.description || post.excerpt,
                          }}
                          itemProp="description"
                        />
                      </section>
                    </article>
                  </div>
                </li>
              );
            })}
          </ol>
          <div className="view-all-">
            <Link className="nav-link" to="/posts">
              View All
            </Link>
          </div>
        </div>
      </Mobile>
    </div>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          categories
          tags
          thumbnail {
            id
            absolutePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        tags
        postMetaData
        title
        date(formatString: "MMMM DD, YYYY")
        description
        categories
        tags
        author
        thumbnail {
          id
          absolutePath
          relativePath

          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
