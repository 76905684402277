import React from "react";
import styled from "styled-components";
import {
  useTable,
  useBlockLayout,
  useResizeColumns,
  useSortBy,
} from "react-table";
import _ from "lodash";
import SortIcon from "@material-ui/icons/Sort";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
// import makeData from "./makedata";

import { withStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    right: 0,
    marginRight: "11px",
  },
});

const Styles = styled.div`
  .table-container {
    margin-bottom: 30px;
    position: relative;
    overflow-x: auto;
    overflow-y: scroll;
    min-height: 200px;
    max-height: 504px;
    border: 1px solid black;
    border-radius: 3px;
    border-left: none;
    width: fit-content;
    padding: 2px;
    max-width: 100%;

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    ::-webkit-scrollbar-track {
      background: #f5f5f5; /* color of the tracking area */
    }

    ::-webkit-scrollbar-thumb {
      height: 60px;
      background-color: #6d6d8a; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
    }
    .table {
      display: inline-block;
      border-spacing: 0;
      border: 1px solid black;
      font-size: 13px;
      .tr {
        :last-child {
          .td {
            border-bottom: 0;
          }
        }
      }
      .th {
        background: #f5f5f5;
        font-weight: bold;
        display: flex;
      }
      .td,
      .th {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        ${
          "" /* In this example we use an absolutely position resizer,
       so this is required. */
        }
        position: relative;

        :last-child {
          border-right: 0;
        }

        .resizer {
          display: inline-block;
          width: 6px;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateX(50%);
          z-index: 1;
          ${"" /* prevents from scrolling while dragging on touch devices */}
          touch-action:none;

          &.isResizing {
            background: #2d7ff9;
          }
        }
      }
    }
  }
`;

function Table({ columns, data, title }) {
  const classes = useStyles();
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 50,
      width: 180,
      maxWidth: 400,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    resetResizing,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout,
    useResizeColumns,
    useSortBy
  );

  return (
    <>
      <div>
        <h6 style={{ fontSize: "16px" }}>{title}</h6>
        <div className="table-container">
          <div {...getTableProps()} className="table">
            <div>
              {headerGroups.map((headerGroup) => {
                return (
                  <div {...headerGroup.getHeaderGroupProps()} className="tr">
                    {headerGroup.headers.map((column) => {
                      return (
                        <div
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="th">
                          {column.render("Header")}
                          {/* Use column.getResizerProps to hook up the events correctly */}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ArrowDropDownIcon className={classes.root} />
                              ) : (
                                <ArrowDropUpIcon className={classes.root} />
                              )
                            ) : (
                              <SortIcon className={classes.root} />
                            )}
                          </span>
                          <div
                            {...column.getResizerProps()}
                            className={`resizer ${
                              column.isResizing ? "isResizing" : ""
                            }`}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>

            <div {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className="tr">
                    {row.cells.map((cell) => {
                      return (
                        <div {...cell.getCellProps()} className="td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

class InteractiveTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table_data: {
        table_title: "",
        rows: [],
        columns: [],
      },
    };
  }
  componentDidMount() {
    import(`../../content/blog/${this.props.data.path}`).then((m) =>
      this.setState({ table_data: m.default })
    );
  }

  render() {
    return (
      <div>
        <Styles>
          <Table
            columns={this.state.table_data.columns}
            title={this.state.table_data.table_title}
            data={this.state.table_data.rows}
          />
        </Styles>
      </div>
    );
  }
}

export default InteractiveTable;
