import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
import "../interactiveMap.css";
import _ from "lodash";
import {
  MAPBOX_TOKEN,
  Marker_COLORS,
  CRUSTDATA_PLATFORM_URL,
  MARKER_MAP_STYLE,
} from "../environment";
import { withStyles } from "@material-ui/core/styles";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import Button from "@material-ui/core/Button";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import { PrettoSlider, LegendInfo } from "./interactiveMapHelper";
import CircularProgress from "@material-ui/core/CircularProgress";

import styled from "styled-components";

const MapContainer = styled.div`
  display: flex;
  height: 82%;
  width: 100%;
  flex-direction: column;
  flex-flow: wrap;
`;
const useStyles = (theme) => ({
  root: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    width: "-webkit-fill-available",
  },
  margin: {
    height: theme.spacing(3),
  },
  markLabel: {
    fontSize: "10px",
  },
});

mapboxgl.accessToken = MAPBOX_TOKEN;

/**
 * Downgraded mapbox-gli from 2.2.0 to 1.13 to fix display issue when site is served from the server.
 https:stackoverflow.com/questions/65394014/how-to-make-mapbox-load-on-gatsby-site-build-succeeds-but-map-not-displaying-d#
 * 
 */

class InteractiveTilesetTimeSliderMap extends Component {
  // https://stackoverflow.com/questions/59542854/typeerror-mapboxgl-default-map-is-not-a-constructor
  map;
  randomId;
  tickerNames = [];
  constructor(props) {
    super(props);
    /**
     * hiddenCompanyName: it is a list which contains all the company names which are hidden by user
     * by pressing toggle button in legend.
     *
     * legendInfo: it is a list of objects. Each object has  3 keys i.e key, value, status. status will
     * decide whether company name is hidden or not. iteration is made over legendInfo to generate LegendInfo
     * component.
     */
    this.state = {
      lng: 78.723,
      lat: 21.41,
      zoom: 3.55,
      hiddenCompanyName: [],
      legendInfo: [],
      slider_markers: [],
      slider_value: null,
      play_status: false,
      slider_maxed_out: false,
      is_loading: null,
    };
    /**
     * this.state sample:
     * {"lng":79.0938,
     * "lat":21.5304,
     * "zoom":4.01,
     * "hiddenCompanyName":[],
     * "legendInfo":[{"key":0,"value":["Zomato","#a15ee0"],"status":true}],
     * "slider_markers":[{"value":1609286400000,"label":"12/2020"},{"value":1616371200000,"label":"03/2021"},{"value":1621641600000,"label":"05/2021"}],
     * "slider_value":1609286400000,
     * "play_status":false,
     * "slider_maxed_out":false,
     * "is_loading":null,
     * "slider_info":{"min":1609286400000,"max":1621641600000}}
     */
  }

  componentDidMount() {
    this.randomId = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    let mounted_id = document.getElementById("mapContainer");
    if (mounted_id) {
      mounted_id.setAttribute("id", this.randomId);
    }
    this.map = new mapboxgl.Map({
      container: this.randomId,
      // Added ?optimize=true to make map load fast but remove it if it messes things up while rendering data on the fly
      style: MARKER_MAP_STYLE,
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
      preserveDrawingBuffer: true,
    });

    this.map.on("move", () => {
      this.setState({
        lng: this.map.getCenter().lng.toFixed(4),
        lat: this.map.getCenter().lat.toFixed(4),
        zoom: this.map.getZoom().toFixed(2),
      });
    });
    this.createTimeSeriesMap();
    this.map.on("load", () => {
      this.map.addSource("points", {
        type: "vector",
        url: this.props.data.tileset_id,
      });
      this.map.addLayer({
        id: "competitors",
        type: "circle",
        source: "points",
        "source-layer": this.props.data.source_layer,
        paint: {
          // make circles larger as the user zooms from z12 to z22
          "circle-radius": {
            base: 1.75,
            stops: [
              [17, 4],
              [22, 180],
            ],
          },
          "circle-color": this.colorPickerForMarker(),
        },
      });
      this.filterLayerWithDate(this.state.slider_info.min);
    });
    //  ://stackoverflow.com/questions/42483449/mapbox-gl-js-export-map-to-png-or-pdf
    var dpi = 300;
    Object.defineProperty(window, "devicePixelRatio", {
      get: function () {
        return dpi / 96;
      },
    });
    this.map.dragRotate.disable();
    this.map.setMaxZoom(12);
    this.map.touchZoomRotate.disableRotation();
  }

  createTimeSeriesMap() {
    let distinct_dates_obj = this.getSliderMarkers();
    let { min, max } = this.getSliderInfo(distinct_dates_obj);
    this.setState({
      slider_markers: distinct_dates_obj,
      slider_info: { min, max },
      slider_value: min,
    });
  }

  /**
   *
   * @param {*} selected_number_date
   * Sample: 1609286400000
   */
  filterLayerWithDate(selected_number_date) {
    let filter_expression;
    let index_of_selected_time_obj = this.state.slider_markers.findIndex(
      (ele) => ele.value === selected_number_date
    );

    if (index_of_selected_time_obj === 0) {
      filter_expression = [
        "all",
        ["<=", ["get", "dateAdded"], selected_number_date],
      ];
    } else {
      let prev_number_date = this.state.slider_markers[
        index_of_selected_time_obj - 1
      ].value;

      filter_expression = [
        "all",
        ["<=", ["get", "dateAdded"], selected_number_date],
        [">", ["get", "dateUpdate"], prev_number_date],
      ];
    }
    this.map.setFilter("competitors", filter_expression);

    // this.map.on("render", this.stopSpinner);
  }
  stopSpinner = (e) => {
    if (e.target && e.target.loaded()) {
      this.loadingSpinner(false);
      this.map.off("render", this.stopSpinner);
    }
  };
  loadingSpinner = (status) => {
    this.setState({ is_loading: status });
  };

  /**
   * From this.props.data.timeseries_indices list this method will convert indices in format used by slider component.
     Return Example:  distinct_dates_obj: [{"value":1590391870474,"label":"02/2012"},{"value":1592551829023,"label":"05/2021"}]
  */
  getSliderMarkers = () => {
    let distinct_dates_obj = [];
    let date_object;
    this.props.data.timeseries_indices.map((date) => {
      date_object = new Date(date);
      distinct_dates_obj.push({
        value: date_object.valueOf(),
        label: `${
          parseInt(date_object.toLocaleDateString().split("/")[0]) <= 9
            ? "0" + parseInt(date_object.toLocaleDateString().split("/")[0])
            : parseInt(date_object.toLocaleDateString().split("/")[0])
        }/${date_object.toDateString().split(" ").pop()}`,
      });
    });
    distinct_dates_obj.sort((a, b) =>
      a.value > b.value ? 1 : b.value > a.value ? -1 : 0
    );
    return distinct_dates_obj;
  };

  /**
   * Method return mapbox expression that is used to associate color to each company name marker.
   * Return sample: ["match", ["get", "companyName"], "Zomato", "#a15ee0","#FF0000"]
   */
  colorPickerForMarker = () => {
    let marker_color = ["match", ["get", "companyName"]];
    let ticker_list = this.props.data.company_names;
    let temporary_legend_info = [];
    let hidden_company_name, legend_info_obj_of_hidden_marker;
    /**
   * This loop populates a list with marker objects. If a particular company name is present
      in hiddenCompanyName list then coresponding object in this.state.legendInfo with same company
       name will be pushed in the temporary_legendInfo list. This will prevent setting default status to
       true to already hidden company names.
   */
    for (let j = 0; j < ticker_list.length; j++) {
      hidden_company_name = this.state.hiddenCompanyName.find(
        (ele) => ele === ticker_list[j]
      );

      if (hidden_company_name) {
        legend_info_obj_of_hidden_marker = this.state.legendInfo.find(
          (ele, index) => ele.value[0] === hidden_company_name
        );
        temporary_legend_info.push(
          this.state.legendInfo[
            this.state.legendInfo.indexOf(legend_info_obj_of_hidden_marker)
          ]
        );
      } else {
        temporary_legend_info.push({
          key: j,
          value: [ticker_list[j], Marker_COLORS[j]],
          status: true,
        });
      }

      if (!this.state.hiddenCompanyName.includes(ticker_list[j])) {
        marker_color.push(ticker_list[j]);
        marker_color.push(Marker_COLORS[j]);
      }
    }
    this.setState({ legendInfo: temporary_legend_info });
    // default color in case no match is found
    marker_color.push("#FF0000");
    return marker_color;
  };

  /**
   * Method gets triggred when a particular legend item is clicked. This method is responsible
   * for marker toggeling. If status of particular marker is true then it toggled to false and vice versa.
   * According to  the status value it triggers  dedicated toggeling method i.e. onMarkerShow and onMarkerhide.
   * legendInfo state is not updated with in this method to bundle setstate.
   * JSON.parse() is used for deep copying.
   */
  onMarkerToggle = (companyName, id) => {
    let duplicate_legend = JSON.parse(JSON.stringify(this.state.legendInfo));
    duplicate_legend[id].status = !duplicate_legend[id].status;
    if (duplicate_legend[id].status === true) {
      // this.onMarkerShow(companyName, duplicate_legend);
    } else {
      // this.onMarkerHide(companyName, duplicate_legend);
    }
  };

  /**
   * Methods returns a list of all the company names from marker_legend which has "status" true.
   * @param {*} marker_legend
   *
   */
  getVisibleMarkers = (marker_legend) => {
    let visible_markers = [];
    marker_legend.map((ele) => {
      if (ele.status) {
        visible_markers.push(ele.value[0]);
      }
    });
    if (visible_markers.length === 1) {
      return visible_markers[0];
    }
    return visible_markers;
  };
  /**
   *  This method is called by onMarkerToggle(). Function will remove already existing company name from state's hiddenCompanyName
   * @param {string} companyName: name of the company on which toggle operation is to be executed
   * @param {List of objects} duplicate_legend: after making changes in onMarker the duplicate legend
   * indo is sent as an parameter to this funciton so that legendInfo and hiddenCompanyName could be
   * update together. this will save calling setstate multiple times.
   */
  onMarkerShow = (companyName, duplicate_legend) => {
    let duplicate_hidden_tickers = JSON.parse(
      JSON.stringify(this.state.hiddenCompanyName)
    );
    let updated_ticker_display_list = duplicate_hidden_tickers.filter(
      (ele) => ele !== companyName
    );
    let dummy_visible_markers = this.getVisibleMarkers(duplicate_legend);
    this.filterMarkerLayer(dummy_visible_markers);

    this.setState({
      hiddenCompanyName: updated_ticker_display_list,
      legendInfo: duplicate_legend,
      is_loading: null,
    });
  };
  /**
   *  This method is called by onMarkerToggle(). Function will add company name to state's hiddenCompanyName
   * @param {string} companyName: name of the company on which toggle operation is to be executed
   * @param {List of objects} duplicate_legend: after making changes in onMarker the duplicate legend
   * indo is sent as an parameter to this funciton so that legendInfo and hiddenCompanyName could be
   * update together. this will save calling setstate multiple times.
   */
  onMarkerHide = (companyName, duplicate_legend) => {
    let duplicate_hidden_tickers = JSON.parse(
      JSON.stringify(this.state.hiddenCompanyName)
    );
    duplicate_hidden_tickers.push(companyName);
    let dummy_visible_markers = this.getVisibleMarkers(duplicate_legend);
    this.filterMarkerLayer(dummy_visible_markers);
    this.setState({
      hiddenCompanyName: duplicate_hidden_tickers,
      legendInfo: duplicate_legend,
      is_loading: null,
    });
  };

  filterMarkerLayer(visible_markers) {
    if (this.map.getLayer("competitors") !== "undefined") {
      this.map.removeLayer("competitors");
    }
    let marker_color_expression = this.colorPickerForMarker();
    this.map.addLayer({
      id: "competitors",
      type: "circle",
      source: "points",
      "source-layer": this.props.data.source_layer,
      paint: {
        // make circles larger as the user zooms from z12 to z22
        "circle-radius": {
          base: 1.75,
          stops: [
            [17, 4],
            [22, 180],
          ],
        },
        "circle-color": marker_color_expression,
      },
    });

    let marker_filter = [
      "match",
      ["get", "companyName"],
      visible_markers,
      true,
      false,
    ];
    this.map.setFilter("competitors", marker_filter);
    this.map.on("render", this.stopSpinner);
  }
  /**
   * This method will be called when silder is moved
   * @param {Event} event
   * @param {Number} value-> 1616371200000
   */
  sliderHandleChange = (event, value) => {
    this.filterLayerWithDate(value);
    this.setState({
      slider_value: value,
    });
  };
  /**
   *
   * @param {*} data
   * Sample: [{value: 1609286400000, label: "12/2020"}, {value: 1616371200000, label: "03/2021"},{value: 1621641600000, label: "05/2021"}]
   */
  getSliderInfo = (data) => {
    data.sort(function (a, b) {
      return a.value - b.value;
    });
    var min = data[0],
      max = data[data.length - 1];
    return { min: min.value, max: max.value };
  };

  /**
   * This method calculates next slider value and sends it as an argument to filterLayerWithDate().
   */
  updateMap = () => {
    let { slider_markers, slider_info, slider_value } = this.state;
    let toggle = false;
    let new_slider_value = null;
    if (this.state.slider_maxed_out) {
      new_slider_value = slider_info.min;
    } else {
      for (let i = 0; i < slider_markers.length; i++) {
        if (toggle) {
          new_slider_value = slider_markers[i].value;
          break;
        }
        if (slider_markers[i].value === slider_value) {
          toggle = true;
        }
      }
    }

    // If slider reaches max value then clear the interaval
    if (new_slider_value === slider_info.max) {
      clearInterval(this.interval);
      this.setState(
        {
          slider_value: new_slider_value,
          play_status: false,
          slider_maxed_out: true,
        },
        () => {
          this.filterLayerWithDate(new_slider_value);
        }
      );
      return;
    }
    this.setState(
      {
        slider_value: new_slider_value,
        slider_maxed_out: false,
      },
      () => {
        this.filterLayerWithDate(new_slider_value);
      }
    );
  };

  /**
   * Method is a callback when play button is pressed. It starts a function loop (using setInterval()) that calls upadteMap() in every 2 sec.
   */
  onPlay = () => {
    this.setState({
      play_status: true,
    });
    // when slider reaches the last date, this conditional statement is executed. It set the slider_value back to the oldest date.
    if (this.state.slider_maxed_out) {
      this.setState(
        (prevState) => {
          return {
            slider_value: prevState.slider_info.min,
          };
        },
        () => {
          this.interval = setInterval(this.updateMap, 2000);
        }
      );
    } else {
      // https://medium.com/@staceyzander/setinterval-and-clearinterval-in-react-b1d0ee1e1a6a
      this.interval = setInterval(this.updateMap, 2000);
    }
  };
  onPause = () => {
    clearInterval(this.interval);
    this.setState({ play_status: false });
  };

  render() {
    const { classes } = this.props;
    let mapbox_id = this.randomId ? this.randomId : "mapContainer";
    return (
      <div className="interactiveMap-container">
        <div>
          <h4 style={{ fontSize: "16px", margin: "0px" }}>
            {this.props.data.hasOwnProperty("map_title")
              ? this.props.data.map_title
              : "Mapbox Map"}
          </h4>
          <ul id="Legend">
            {this.state.legendInfo.map((ele, index) => {
              return (
                <LegendInfo
                  index={ele.key}
                  key={ele.key}
                  onMarkerToggle={this.onMarkerToggle}
                  onMarkerShow={this.onMarkerShow}
                  onMarkerHide={this.onMarkerHide}
                  companyName={ele.value[0]}
                  markerColor={ele.value[1]}
                  status={ele.status}
                />
              );
            })}
          </ul>
        </div>
        <div className="map-main-container">
          <MapContainer className="MapView" id={mapbox_id}>
            {this.state.is_loading && (
              <div id="loading-background">
                <CircularProgress color="secondary" />
              </div>
            )}
          </MapContainer>
        </div>
        {this.state.slider_markers.length !== 0 && (
          <div className={classes.root}>
            <div className="play-button">
              {this.state.play_status ? (
                <Button onClick={this.onPause}>
                  <PauseCircleFilledIcon fontSize="large" />
                </Button>
              ) : (
                <Button onClick={this.onPlay}>
                  <PlayCircleFilledIcon fontSize="large" />
                </Button>
              )}
            </div>
            <div className="slider">
              <PrettoSlider
                classes={{
                  markLabel: classes.markLabel,
                }}
                min={this.state.slider_markers[0].value}
                max={
                  this.state.slider_markers[
                    this.state.slider_markers.length - 1
                  ].value
                }
                value={this.state.slider_value}
                step={null}
                aria-labelledby="discrete-slider-always"
                onChange={this.sliderHandleChange}
                marks={this.state.slider_markers}
              />
            </div>
          </div>
        )}
        <div>
          <a target="_blank" href={CRUSTDATA_PLATFORM_URL}>
            Source: Crustdata Alternative Data
          </a>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(InteractiveTilesetTimeSliderMap);
