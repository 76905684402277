import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
import "../interactiveMap.css";
import _, { get, isEmpty } from "lodash";
import {
  MAPBOX_TOKEN,
  Marker_COLORS,
  MARKER_MAP_STYLE,
  CRUSTDATA_PLATFORM_URL,
} from "../environment";
import { withStyles } from "@material-ui/core/styles";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import Button from "@material-ui/core/Button";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import {
  PrettoSlider,
  getQuantile,
  LegendInfo,
  getRowsWithColumnsMatching,
} from "./interactiveMapHelper";
import CircularProgress from "@material-ui/core/CircularProgress";

import styled from "styled-components";

const MapContainer = styled.div`
  display: flex;
  height: 82%;
  width: 100%;
  flex-direction: column;
  flex-flow: wrap;
`;
const useStyles = (theme) => ({
  root: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    width: "-webkit-fill-available",
  },
  margin: {
    height: theme.spacing(3),
  },
  markLabel: {
    fontSize: "10px",
  },
});

mapboxgl.accessToken = MAPBOX_TOKEN;
let temporary_legend_info = [];

/**
 * Downgraded mapbox-gli from 2.2.0 to 1.13 to fix display issue when site is served from the server.
 https:stackoverflow.com/questions/65394014/how-to-make-mapbox-load-on-gatsby-site-build-succeeds-but-map-not-displaying-d#
 * 
 */

class InteractiveTimeSliderMap extends Component {
  // https://stackoverflow.com/questions/59542854/typeerror-mapboxgl-default-map-is-not-a-constructor
  map;
  randomId;
  tickerNames = [];
  constructor(props) {
    super(props);
    /**
     * hiddenCompanyName: it is a list which contains all the company names which are hidden by user
     * by pressing toggle button in legend.
     *
     * legendInfo: it is a list of objects. Each object has  3 keys i.e key, value, status. status will
     * decide whether company name is hidden or not. iteration is made over legendInfo to generate LegendInfo
     * component.
     */
    this.state = {
      lng: 78.723,
      lat: 21.41,
      zoom: 3.55,
      hiddenCompanyName: [],
      legendInfo: [],
      slider_markers: [],
      slider_value: null,
      play_status: false,
      open_stores: {},
      map_data: {},
      is_map_loaded: false,
      is_loading: null,
    };
    /**
     * {
   "lng":"78.9629",
   "lat":"20.5937",
   "zoom":"4.00",
   "hiddenCompanyName":[
      
   ],
   "legendInfo":[
      {
         "key":0,
         "value":[
            "Pizza Hut India",
            "#a15ee0"
         ],
         "status":true
      }
   ],
   "slider_markers":[
      {
         "value":1590391870474,
         "label":"05/2020"
      },
      {
         "value":1619049600000,
         "label":"04/2021"
      },...
   ],
   "slider_value":1590391870474,
   "play_status":false,
   "open_stores":[
      [
         "Pizza Hut India",
         "POINT(74.7823 13.3486)",
         "2020-05-25T07:30:21.595205Z",
         "2021-04-22T00:00:00Z",
         
      ],...
   ]
    "map_data":{map_title: "", points:{fields:[]}, rows:[]}
    "slider_info":{
      "min":1590391870474,
      "max":1619049600000
    }
}
     */
  }

  componentDidMount() {
    import(`../../content/blog/${this.props.data.path}`).then((m) =>
      this.setState({ map_data: m.default }, () => {})
    );
    this.randomId = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    let mounted_id = document.getElementById("mapContainer");
    if (mounted_id) {
      mounted_id.setAttribute("id", this.randomId);
    }
    this.map = new mapboxgl.Map({
      container: this.randomId,
      // Added ?optimize=true to make map load fast but remove it if it messes things up while rendering data on the fly
      style: MARKER_MAP_STYLE,
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
      preserveDrawingBuffer: true,
    });

    this.map.on("move", () => {
      this.setState({
        lng: this.map.getCenter().lng.toFixed(4),
        lat: this.map.getCenter().lat.toFixed(4),
        zoom: this.map.getZoom().toFixed(2),
      });
    });

    // This callback is very IMPORTANT. is_map_loaded presvents further re-rendering until map is loaded. Once it is loaded we can add/remove layer and source.
    this.map.on("load", () => {
      this.setState({ is_map_loaded: true });
    });
    //  ://stackoverflow.com/questions/42483449/mapbox-gl-js-export-map-to-png-or-pdf
    var dpi = 300;
    Object.defineProperty(window, "devicePixelRatio", {
      get: function () {
        return dpi / 96;
      },
    });
    this.map.dragRotate.disable();
    this.map.setMaxZoom(12);
    this.map.touchZoomRotate.disableRotation();

    this.createTimeSeriesMap(true);
  }

  // This is copied from platform. This is not the right way   please change it when you interegrate redux.
  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.is_map_loaded &&
      (!_.isEqual(nextState.map_data, this.state.map_data) ||
        !_.isEqual(nextState.legendInfo, this.state.legendInfo) ||
        !_.isEqual(nextState.is_loading, this.state.is_loading) ||
        !_.isEqual(nextState.slider_value, this.state.slider_value))
    ) {
      return true;

      return true;
    } else if (!_.isEqual(nextState.is_map_loaded, this.state.is_map_loaded)) {
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.is_loading === null) {
      this.createTimeSeriesMap(true);
    } else if (!_.isEqual(prevState.is_loading, this.state.is_loading)) {
      this.createTimeSeriesMap(false);
    }
  };
  createTimeSeriesMap(is_initial_call) {
    if (!_.isEmpty(this.state.map_data)) {
      //date_added_and_updated_list= [{added: {value: 1590391899236, label: "05/2020"}
      // updated: {value: 1619049600000, label: "04/2021"}}...]
      let date_added_and_updated_list = this.getRowDateDetails();
      //distinct_dates_obj: [{value: 1590391870474, label: "05/2020"}
      // 1: {value: 1594366274108, label: "07/2020"},
      // 2: {value: 1597735832377, label: "08/2020"},...]
      let distinct_dates_obj = this.getSliderMarkers(
        date_added_and_updated_list
      );

      let { min, max } = this.getSliderInfo(distinct_dates_obj);

      //    geoJson=  [{geometry:{
      // coordinates: [
      // 0: "74.8187538"
      // 1: "34.0736635"]
      // type: "Point"}
      // properties: {companyName: "Pizza Hut India"}}...]
      let geoJson = this.formatRowDataToGeoJSON(
        this.state.map_data.points.rows
      );
      this.insertGeoJsonInMap(is_initial_call, geoJson);

      this.setState({
        slider_markers: distinct_dates_obj,
        slider_info: { min, max },
        slider_value: min,
        open_stores: this.state.map_data.points.rows,
      });
    }
  }

  /**
   * This method inserts data in this.map and as result user will be able to see markers on the map.
   * isIntialMount seprates insertion process based on inital mounting (by componentDidMmount()) and updating already mounted map.
   * Different treatment is reasoned by the fact that there is diffrent procedure for mounting the map and updating the map
   * @param {Bool} isInitialMount
   * @param {List} geoJson
   * Parameter Example: isInitialMount: True, geoJson:
   * Return Example: [[{"type":"Feature","geometry":{"type":"Point","coordinates":["74.8187538","34.0736635"]},"properties":{"companyName":"Pizza Hut India"}},{"type":"Feature","geometry":{"type":"Point","coordinates":["74.7823","13.3486"]},"properties":{"companyName":"Pizza Hut India"}},{"type":"Feature","geometry":{"type":"Point","coordinates":["76.95846","8.51656"]},"properties":{"companyName":"Pizza Hut India"}},...]]
   */
  insertGeoJsonInMap(isInitialMount, geoJson) {
    if (isInitialMount) {
      this.loadingSpinner(true);
    }
    var map_src_check = this.map.getSource("points");
    if (typeof map_src_check !== "undefined") {
      // Remove map layer & source.
      this.map.getSource("points").setData({
        type: "FeatureCollection",
        features: geoJson,
      });
      this.map.removeLayer("competitors");
    } else {
      this.map.addSource("points", {
        type: "geojson",
        tolerance: 4,
        data: {
          type: "FeatureCollection",
          features: geoJson,
        },
      });
    }
    this.map.addLayer({
      id: "competitors",
      type: "circle",
      source: "points",
      paint: {
        // make circles larger as the user zooms from z12 to z22
        "circle-radius": {
          base: 1.75,
          stops: [
            [17, 4],
            [22, 180],
          ],
        },
        "circle-color": this.colorPickerForMarker(
          this.state.map_data.points.rows,
          this.state.map_data.points.fields
        ),
      },
    });
    this.map.on("render", this.stopSpinner);
  }
  stopSpinner = (e) => {
    if (e.target && e.target.loaded()) {
      this.loadingSpinner(false);
      this.map.off("render", this.stopSpinner);
    }
  };
  loadingSpinner = (status) => {
    this.setState({ is_loading: status });
  };

  /**
   * This method will take date as a parameter and return list of all the rows which capsulates the params date. 
   * It will check if param date fall in between date_added and date_updated. 
   * @param {Number} date_value
   * Param Example: 1594366274108
   * Return Exaple: [["PIZZAHUTINDIA", "PRIVATE", "https://www.pizzahut.co.in/", "Pizza Hut India", "POINT(74.8187538 34.0736635)", "2020-05-25T07:31:39.236323Z", "P396", "2021-04-22T00:00:00Z", "Srinagar - City Walk Mall", "City Walk Mall, Mouza Kothi Bagh, Opp. Catholic Church, Jammu, Jammu & Kashmir", "Jammu", "", "39883988", "https://api.pizzahut.io/v1/huts/nearby?limit=100&sectors=in-1&lat=32.733333&lon=74.866667", "", false, null, null, null, null, "Srinagar G.P.O.", "190001", "SRINAGAR", "Jammu & Kashmir", null]
1: (25) ["PIZZAHUTINDIA", "PRIVATE", "https://www.pizzahut.co.in/", "Pizza Hut India", "POINT(74.7823 13.3486)", "2020-05-25T07:30:21.595205Z", "12190", "2021-04-22T00:00:00Z", "Manipal - Shiror Tower", "No 5, Ground Floor, Shiror Tower, Manipal, Karnataka", ...] ...]
   */
  getOpenStores = (date_value) => {
    let rows = this.state.map_data.points.rows;
    let active_stores = [];
    let { date_updated_index, date_added_index } = this.getDatesFromRows(
      this.state.map_data.points.fields
    );
    let updated_date_value;
    let added_date_value;
    for (let i = 0; i < rows.length; i++) {
      added_date_value = new Date(rows[i][date_added_index]);
      updated_date_value = new Date(rows[i][date_updated_index]);
      if (
        date_value <= updated_date_value.valueOf() &&
        date_value >= added_date_value.valueOf()
      ) {
        active_stores.push(rows[i]);
      }
    }
    return active_stores;
  };

  /**
   * Extract distinct ticker names from rows.
   * @param {list} rows
   * @param {list} fields
   */
  getCompanyList = (rows, fields) => {
    let ticker_list = [];
    let ticker_string = rows[0][this.getTickernameIndex(fields)];
    ticker_list.push(ticker_string);

    for (let i = 0; i < rows.length; i++) {
      if (rows[i][this.getTickernameIndex(fields)] !== ticker_string) {
        ticker_string = rows[i][this.getTickernameIndex(fields)];
        ticker_list.push(ticker_string);
      }
    }

    let distinct_tickers = [...new Set(ticker_list)];

    // Following conditional statement prevents shuffle of marker colors
    if (this.tickerNames.length > 0) {
      let updated_ticker_list = this.tickerNames;

      let difference = distinct_tickers.filter(
        (x) => !this.tickerNames.includes(x)
      );
      if (difference.length > 0) {
        difference.forEach((ticker) => updated_ticker_list.push(ticker));
        this.tickerNames = updated_ticker_list;
        return this.tickerNames;
      }
      // else condition updates legend (otherwise removed tickers will still be reflected in the legend)
      else {
        // temporary_ticker_list is created to prevent removing element during loop.
        let temporary_ticker_list = [];
        this.tickerNames.map((ele) => {
          if (distinct_tickers.includes(ele)) {
            temporary_ticker_list.push(ele);
          }
        });
        this.tickerNames = temporary_ticker_list;
        return this.tickerNames;
      }
    }
    this.tickerNames = distinct_tickers;
    return this.tickerNames;
  };

  /**
   * This method will get all the distinct dates from the  data extracted from all the rows.
   * This list of distinct dates will be then used for slider marker
   * @param {List} date_added_and_updated_list
   * Parameter Example: [{"added":{"value":1590391899236,"label":"202005"},"updated":{"value":1619049600000,"label":"202104"}},...]
   * Return Example:  distinct_dates_obj: [{"value":1590391870474,"label":"202005"},{"value":1592551829023,"label":"202006"},{"value":1594366274108,"label":"202007"},{"value":1597735832377,"label":"202008"},{"value":1600155021810,"label":"202009"},{"value":1603956661723,"label":"202010"},{"value":1605684636927,"label":"202011"},{"value":1608017458230,"label":"202012"},{"value":1610782209011,"label":"202101"},{"value":1614151877587,"label":"202102"},{"value":1616716800000,"label":"202103"},{"value":1619049600000,"label":"202104"}]
   */
  getSliderMarkers = (date_added_and_updated_list) => {
    let distinct_dates_obj = [];

    if (
      !_.isEmpty(this.state.map_data) &&
      this.state.map_data.hasOwnProperty("timeseries_indices")
    ) {
      let date_object;

      this.state.map_data.timeseries_indices.map((date) => {
        date_object = new Date(date);
        distinct_dates_obj.push({
          value: date_object.valueOf(),
          label: `${
            parseInt(date_object.toLocaleDateString().split("/")[0]) <= 9
              ? "0" + parseInt(date_object.toLocaleDateString().split("/")[0])
              : parseInt(date_object.toLocaleDateString().split("/")[0])
          }/${date_object.toDateString().split(" ").pop()}`,
        });
      });
      distinct_dates_obj.sort((a, b) =>
        a.value > b.value ? 1 : b.value > a.value ? -1 : 0
      );
      return distinct_dates_obj;
    } else {
      let distinct_dates = [];
      for (let i = 1; i < date_added_and_updated_list.length; i++) {
        if (
          !distinct_dates.includes(date_added_and_updated_list[i].updated.label)
        ) {
          distinct_dates.push(date_added_and_updated_list[i].updated.label);
          distinct_dates_obj.push(date_added_and_updated_list[i].updated);
        } else if (
          !distinct_dates.includes(date_added_and_updated_list[i].added.label)
        ) {
          distinct_dates.push(date_added_and_updated_list[i].added.label);
          distinct_dates_obj.push(date_added_and_updated_list[i].added);
        }
      }
      // Sort dates from old to new
      distinct_dates_obj.sort((a, b) =>
        a.value > b.value ? 1 : b.value > a.value ? -1 : 0
      );
      let evenly_distributed_marker_list = this.getEvenlyDistributedList(
        distinct_dates_obj,
        10
      );
      return evenly_distributed_marker_list;
    }
  };

  /**
   *
   * @param {List} list
   * @param {Number} limit_num
   * this method will take array of length m and returns evenly distributed array of lengh limit_num
   */
  getEvenlyDistributedList(list, limit_num) {
    let len = parseFloat(list.length);
    let evenly_distributed_list = [];
    for (let i = 0; i < limit_num; i++) {
      evenly_distributed_list.push(
        list[parseInt(Math.ceil((i * len) / limit_num))]
      );
    }
    return evenly_distributed_list;
  }

  /**
   * Return object containing index of date_added and date_updated
   * from points.fields
   * @param {list} field_info
   */
  getDatesFromRows = (field_info) => {
    let date_added_index, date_updated_index;
    for (let i = 0; i < field_info.length; i++) {
      if (field_info[i].api_name === "date_added") {
        date_added_index = i;
      } else if (field_info[i].api_name === "date_updated") {
        date_updated_index = i;
      }
    }
    return { date_added_index, date_updated_index };
  };

  /**
   * Returns index of column which has api_name "ticker_name".
   * @param {list} field_info
   */
  getTickernameIndex = (field_info) => {
    let index;
    for (let i = 0; i < field_info.length; i++) {
      if (field_info[i].api_name === "company_name") {
        index = i;
        break;
      }
    }
    return index;
  };

  /**
   * Returns index of column which has type "geo_point".
   * @param {list} field_info
   */
  getGeoPointFieldIndex = (field_info) => {
    let index;
    for (let i = 0; i < field_info.length; i++) {
      if (field_info[i].api_name === "coordinates") {
        index = i;
        break;
      }
    }
    return index;
  };

  /**
   * This method extracts all the cordinates from each row data. Extraction is done using regex.
   * While extracting the coordinates from row_data if a particular coordinate belongs to a
   * company nwhich is toggled hidden in legend then such coordinate is not pushed to
   * list_of_formated_coordinates.
   * Returns geoJSON list. With regex function extracts coordinates from POINT(lat log)
   * @param {list} data: list of rows
   *
   */
  formatRowDataToGeoJSON = (row_data) => {
    var regex = /[+-]?\d+(\.\d+)?/g;
    let list_of_formated_coordinates = [];
    let field_index = this.getGeoPointFieldIndex(
      this.state.map_data.points.fields
    );
    let tickerName_index = this.getTickernameIndex(
      this.state.map_data.points.fields
    );

    for (let j = 0; j < row_data.length; j++) {
      // This if condition checks for two conditions. Firtly it checks for presence of coordinates
      //  in the data and secondly it checks if the particular company name is prencent in
      // hiddenCompanyName. If latter is true then it skips pushing object of particular company n
      // in the list of formated coordinates.
      if (
        row_data[j][field_index] &&
        !this.state.hiddenCompanyName.includes(row_data[j][tickerName_index])
      ) {
        list_of_formated_coordinates.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: row_data[j][field_index].match(regex),
          },
          properties: {
            companyName: row_data[j][tickerName_index],
          },
        });
      }
    }
    return list_of_formated_coordinates;
  };

  /** +++++
   * Method map color marker to diffrent companies
   * Method return a list formatted according to circle-color params
   * @param {*} rows : ["RELIANCE","NSE",null,null,null,"https://relianceretail.com/reliance-fresh.html",61,"Reliance Fresh","POINT(77.311803 28.62197)","2020-04-06T09:40:07.187278Z","2763","2020-08-11T07:00:05.596364Z","Kichripur FRESH","Reliance Fresh, R1,R2, East Vinod Nagar, Main Road Khichripur, Delhi - 110091","Delhi","Store_DEL.1763_KHIC@zmail.ril.com","","http://storelocator.ril.com/getAllStores.aspx?flag=false&distance=50&Searchformat=FRESH&latitude=28.666667&longitude=77.216667",null,false,null,null,null,null,"Kalyanpuri","110091","EAST","NCT of Delhi",null]
   * @param {*} fields : {"type":"string","api_name":"ticker_name","hidden":false,"options":[],"summary":"","local_metric":false,"display_name":""}
   */
  colorPickerForMarker = (rows, fields) => {
    let marker_color = ["match", ["get", "companyName"]];
    let ticker_list = this.getCompanyList(rows, fields);
    temporary_legend_info = [];
    let hidden_company_name, legend_info_obj_of_hidden_marker;
    /**
   * This loop populates a list with marker objects. If a particular company name is present
      in hiddenCompanyName list then coresponding object in this.state.legendInfo with same company
       name will be pushed in the temporary_legendInfo list. This will prevent setting default status to
       true to already hidden company names.
   */
    for (let j = 0; j < ticker_list.length; j++) {
      hidden_company_name = this.state.hiddenCompanyName.find(
        (ele) => ele === ticker_list[j]
      );

      if (hidden_company_name) {
        legend_info_obj_of_hidden_marker = this.state.legendInfo.find(
          (ele, index) => ele.value[0] === hidden_company_name
        );
        temporary_legend_info.push(
          this.state.legendInfo[
            this.state.legendInfo.indexOf(legend_info_obj_of_hidden_marker)
          ]
        );
      } else {
        temporary_legend_info.push({
          key: j,
          value: [ticker_list[j], Marker_COLORS[j]],
          status: true,
        });
      }

      if (!this.state.hiddenCompanyName.includes(ticker_list[j])) {
        marker_color.push(ticker_list[j]);
        marker_color.push(Marker_COLORS[j]);
      }
    }
    this.setState({ legendInfo: temporary_legend_info });
    // default color in case no match is found
    marker_color.push("#FF0000");
    return marker_color;
  };

  /**
   * Method gets triggred when a particular legend item is clicked. This method is responsible
   * for marker toggeling. If status of particular marker is true then it toggled to false and vice versa.
   * According to  the status value it triggers  dedicated toggeling method i.e. onMarkerShow and onMarkerhide.
   * legendInfo state is not updated with in this method to bundle setstate.
   * JSON.parse() is used for deep copying.
   */
  onMarkerToggle = (companyName, id) => {
    let duplicate_legend = JSON.parse(JSON.stringify(this.state.legendInfo));
    duplicate_legend[id].status = !duplicate_legend[id].status;
    if (duplicate_legend[id].status === true) {
      this.onMarkerShow(companyName, duplicate_legend);
    } else {
      this.onMarkerHide(companyName, duplicate_legend);
    }
  };
  /**
   *  This method is called by onMarkerToggle(). Function will remove already existing company name from state's hiddenCompanyName
   * @param {string} companyName: name of the company on which toggle operation is to be executed
   * @param {List of objects} duplicate_legend: after making changes in onMarker the duplicate legend
   * indo is sent as an parameter to this funciton so that legendInfo and hiddenCompanyName could be
   * update together. this will save calling setstate multiple times.
   */
  onMarkerShow = (companyName, duplicate_legend) => {
    let duplicate_hidden_tickers = JSON.parse(
      JSON.stringify(this.state.hiddenCompanyName)
    );
    let updated_ticker_display_list = duplicate_hidden_tickers.filter(
      (ele) => ele !== companyName
    );
    this.setState({
      hiddenCompanyName: updated_ticker_display_list,
      legendInfo: duplicate_legend,
      is_loading: null,
    });
  };
  /**
   *  This method is called by onMarkerToggle(). Function will add company name to state's hiddenCompanyName
   * @param {string} companyName: name of the company on which toggle operation is to be executed
   * @param {List of objects} duplicate_legend: after making changes in onMarker the duplicate legend
   * indo is sent as an parameter to this funciton so that legendInfo and hiddenCompanyName could be
   * update together. this will save calling setstate multiple times.
   */
  onMarkerHide = (companyName, duplicate_legend) => {
    let duplicate_hidden_tickers = JSON.parse(
      JSON.stringify(this.state.hiddenCompanyName)
    );
    duplicate_hidden_tickers.push(companyName);
    this.setState({
      hiddenCompanyName: duplicate_hidden_tickers,
      legendInfo: duplicate_legend,
      is_loading: null,
    });
  };

  /**
   * This method iterates over the map_row list and reduce it in the following form:
   * [
   *  {
   *      added: { value: Number ex. 1594366274108
   *               label: String ex. 202003
   *             }
   *      updated: { value: Number ex. 1594366274108
   *               label: String ex. 202003
   *                  }
   *      }
   * ]
   */
  getRowDateDetails() {
    let date_added_and_updated_list = [];
    let get_date_from_timestamp1, get_date_from_timestamp2;
    let { date_updated_index, date_added_index } = this.getDatesFromRows(
      this.state.map_data.points.fields
    );
    //   storing date added and updated in a list.
    for (let i = 0; i < this.state.map_data.points.rows.length; i++) {
      get_date_from_timestamp1 = new Date(
        this.state.map_data.points.rows[i][date_added_index]
      );
      get_date_from_timestamp2 = new Date(
        this.state.map_data.points.rows[i][date_updated_index]
      );

      date_added_and_updated_list.push({
        added: {
          value: get_date_from_timestamp1.valueOf(),
          label: `${
            parseInt(
              get_date_from_timestamp1.toLocaleDateString().split("/")[0]
            ) <= 9
              ? "0" +
                parseInt(
                  get_date_from_timestamp1.toLocaleDateString().split("/")[0]
                )
              : parseInt(
                  get_date_from_timestamp1.toLocaleDateString().split("/")[0]
                )
          }/${get_date_from_timestamp1.toDateString().split(" ").pop()}`,
        },
        updated: {
          value: get_date_from_timestamp2.valueOf(),
          label: `${
            parseInt(
              get_date_from_timestamp2.toLocaleDateString().split("/")[0]
            ) <= 9
              ? "0" +
                parseInt(
                  get_date_from_timestamp2.toLocaleDateString().split("/")[0]
                )
              : parseInt(
                  get_date_from_timestamp2.toLocaleDateString().split("/")[0]
                )
          }/${get_date_from_timestamp2.toDateString().split(" ").pop()}`,
        },
      });
    }
    return date_added_and_updated_list;
  }

  /**
   * This method will be called when silder is moved
   * @param {Event} event
   * @param {Number} value
   */
  sliderHandleChange = (event, value) => {
    let list_of_open_stores = this.getOpenStores(value);
    let geojson_of_open_stores = this.formatRowDataToGeoJSON(
      list_of_open_stores
    );
    this.setState(
      {
        slider_value: value,
        open_stores: list_of_open_stores,
      },
      () => {
        this.insertGeoJsonInMap(false, geojson_of_open_stores);
      }
    );
  };
  getSliderInfo = (data) => {
    data.sort(function (a, b) {
      return a.value - b.value;
    });

    var min = data[0],
      max = data[data.length - 1];
    return { min: min.value, max: max.value };
  };
  updateMap = () => {
    let { slider_markers, slider_info, slider_value } = this.state;
    let toggle = false;
    let new_slider_value = null;
    for (let i = 0; i < slider_markers.length; i++) {
      if (toggle) {
        new_slider_value = slider_markers[i].value;
        break;
      }
      if (slider_markers[i].value === slider_value) {
        toggle = true;
      }
    }
    let list_of_open_stores = this.getOpenStores(new_slider_value);

    let geojson_of_open_stores = this.formatRowDataToGeoJSON(
      list_of_open_stores
    );

    // If slider reaches max value then clear the interaval
    if (new_slider_value === slider_info.max) {
      clearInterval(this.interval);
      this.setState(
        {
          open_stores: list_of_open_stores,
          slider_value: new_slider_value,
          play_status: false,
          // label_and_colors: updated_labels_and_colors,
        },
        () => {
          this.insertGeoJsonInMap(false, geojson_of_open_stores);
        }
      );
      return;
    }
    this.setState(
      {
        open_stores: list_of_open_stores,

        slider_value: new_slider_value,
        play_status: true,
      },
      () => {
        this.insertGeoJsonInMap(false, geojson_of_open_stores);
      }
    );
  };
  onPlay = () => {
    // when slider reaches the last date, this conditional statement is executed. It set the slider_value back to the oldest date.
    if (this.state.slider_value === this.state.slider_info.max) {
      this.setState((prevState) => {
        return {
          slider_value: prevState.slider_info.min,
        };
      });
    }
    // https://medium.com/@staceyzander/setinterval-and-clearinterval-in-react-b1d0ee1e1a6a
    this.interval = setInterval(this.updateMap, 2000);
  };
  onPause = () => {
    clearInterval(this.interval);
    this.setState({ play_status: false });
  };

  render() {
    const { classes } = this.props;
    let mapbox_id = this.randomId ? this.randomId : "mapContainer";
    return (
      <div className="interactiveMap-container">
        <div>
          <h4 style={{ fontSize: "16px", margin: "0px" }}>
            {this.state.map_data ? this.state.map_data.map_title : "Mapbox Map"}
          </h4>
          <ul id="Legend">
            {this.state.legendInfo.map((ele, index) => {
              return (
                <LegendInfo
                  index={ele.key}
                  key={ele.key}
                  onMarkerToggle={this.onMarkerToggle}
                  onMarkerShow={this.onMarkerShow}
                  onMarkerHide={this.onMarkerHide}
                  companyName={ele.value[0]}
                  markerColor={ele.value[1]}
                  status={ele.status}
                />
              );
            })}
          </ul>
        </div>
        <div className="map-main-container">
          <MapContainer className="MapView" id={mapbox_id}>
            {this.state.is_loading && (
              <div id="loading-background">
                <CircularProgress color="secondary" />
              </div>
            )}
          </MapContainer>
        </div>
        {this.state.slider_markers.length !== 0 && (
          <div className={classes.root}>
            <div className="play-button">
              {this.state.play_status ? (
                <Button onClick={this.onPause}>
                  <PauseCircleFilledIcon fontSize="large" />
                </Button>
              ) : (
                <Button onClick={this.onPlay}>
                  <PlayCircleFilledIcon fontSize="large" />
                </Button>
              )}
            </div>
            <div className="slider">
              <PrettoSlider
                classes={{
                  markLabel: classes.markLabel,
                }}
                min={this.state.slider_markers[0].value}
                max={
                  this.state.slider_markers[
                    this.state.slider_markers.length - 1
                  ].value
                }
                value={this.state.slider_value}
                step={null}
                aria-labelledby="discrete-slider-always"
                onChange={this.sliderHandleChange}
                marks={this.state.slider_markers}
              />
            </div>
          </div>
        )}
        <div>
          <a target="_blank" href={CRUSTDATA_PLATFORM_URL}>
            Source: Crustdata Alternative Data
          </a>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(InteractiveTimeSliderMap);
