import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
import "../interactiveMap.css";
import _ from "lodash";
import {
  MAPBOX_TOKEN,
  Marker_COLORS,
  MARKER_MAP_STYLE,
  CRUSTDATA_PLATFORM_URL,
} from "../environment";
import { LegendInfo } from "./interactiveMapHelper";
import styled from "styled-components";
import { Profiler } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
const MapContainer = styled.div`
  display: flex;
  height: 82%;
  width: 100%;
  flex-direction: column;
  flex-flow: wrap;
`;
mapboxgl.accessToken = MAPBOX_TOKEN;
let temporary_legend_info = [];

/**
 * Downgraded mapbox-gli from 2.2.0 to 1.13 to fix display issue when site is served from the server.
 https:stackoverflow.com/questions/65394014/how-to-make-mapbox-load-on-gatsby-site-build-succeeds-but-map-not-displaying-d#
 * 
 */

// Example of OVerlay + Marker-> https://www.businessofbusiness.com/articles/pharmacy-deserts-cvs-walgreens-closed-covid/

export default class InteractiveMap extends Component {
  // https://stackoverflow.com/questions/59542854/typeerror-mapboxgl-default-map-is-not-a-constructor
  map;
  randomId;
  tickerNames = [];
  constructor(props) {
    super(props);
    /**
     * hiddenCompanyName: it is a list which contains all the company names which are hidden by user
     * by pressing toggle button in legend.
     *
     * legendInfo: it is a list of objects. Each object has  3 keys i.e key, value, status. status will
     * decide whether company name is hidden or not. iteration is made over legendInfo to generate LegendInfo
     * component.
     */
    this.state = {
      lng: 78.723,
      lat: 21.41,
      zoom: 3.55,
      key: 0,
      hiddenCompanyName: [],
      legendInfo: [],
      map_data: [],
      is_loading: true,
      is_map_loaded: false,
      geojson: [],
    };
  }

  componentDidMount() {
    let company_names = [];
    import(`../../content/blog/${this.props.data.path}`).then((m) => {
      // m.default.features.map((ele) => {
      //   if (!company_names.includes(ele.properties.companyName)) {
      //     company_names.push(ele.properties.companyName);
      //   }
      // });
      this.setState({ map_data: m.default, company_names });
    });
    this.randomId = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    let mounted_id = document.getElementById("mapContainer");
    if (mounted_id) {
      mounted_id.setAttribute("id", this.randomId);
    }
    this.map = new mapboxgl.Map({
      container: this.randomId,
      // Added ?optimize=true to make map load fast but remove it if it messes things up while rendering data on the fly
      style: MARKER_MAP_STYLE,
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
      preserveDrawingBuffer: true,
    });

    this.map.on("move", () => {
      this.setState({
        lng: this.map.getCenter().lng.toFixed(4),
        lat: this.map.getCenter().lat.toFixed(4),
        zoom: this.map.getZoom().toFixed(2),
      });
    });

    // This callback is very IMPORTANT. is_map_loaded presvents further re-rendering until map is loaded. Once it is loaded we can add/remove layer and source.
    this.map.on("load", () => {
      this.map.addSource("points-src", {
        type: "geojson",
        tolerance: 4,
        data: this.state.map_data,
      });

      this.map.addLayer({
        id: "points-layer",
        type: "circle",
        source: "points-src",
        paint: {
          // make circles larger as the user zooms from z12 to z22
          "circle-radius": {
            base: 1.75,
            stops: [
              [17, 4],
              [22, 180],
            ],
          },
          "circle-color": this.colorPickerForMarker(),
        },
      });
      this.map.on("render", this.stopSpinner);

      this.setState({ is_map_loaded: true });
    });

    //  ://stackoverflow.com/questions/42483449/mapbox-gl-js-export-map-to-png-or-pdf
    var dpi = 300;
    Object.defineProperty(window, "devicePixelRatio", {
      get: function () {
        return dpi / 96;
      },
    });

    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();
  }

  stopSpinner = (e) => {
    if (e.target && e.target.loaded()) {
      this.loadingSpinner(false);
      this.map.off("render", this.stopSpinner);
    }
  };
  loadingSpinner = (status) => {
    this.setState({ is_loading: status });
  };
  /**
   * Extract distinct ticker names from rows.
   * @param {list} rows
   * @param {list} fields
   */
  getCompanyList = () => {
    let ticker_list = [];
    this.state.map_data.features.map((ele) => {
      if (!ticker_list.includes(ele.properties.companyName)) {
        ticker_list.push(ele.properties.companyName);
      }
    });
    return ticker_list;
  };

  /** +++++
   * Function map color marker to diffrent companies
   * Function return a list formatted according to circle-color params
   * @param {*} rows : ["RELIANCE","NSE",null,null,null,"https://relianceretail.com/reliance-fresh.html",61,"Reliance Fresh","POINT(77.311803 28.62197)","2020-04-06T09:40:07.187278Z","2763","2020-08-11T07:00:05.596364Z","Kichripur FRESH","Reliance Fresh, R1,R2, East Vinod Nagar, Main Road Khichripur, Delhi - 110091","Delhi","Store_DEL.1763_KHIC@zmail.ril.com","","http://storelocator.ril.com/getAllStores.aspx?flag=false&distance=50&Searchformat=FRESH&latitude=28.666667&longitude=77.216667",null,false,null,null,null,null,"Kalyanpuri","110091","EAST","NCT of Delhi",null]
   * @param {*} fields : {"type":"string","api_name":"ticker_name","hidden":false,"options":[],"summary":"","local_metric":false,"display_name":""}
   */
  colorPickerForMarker = () => {
    let marker_color = ["match", ["get", "companyName"]];
    let ticker_list = this.getCompanyList();

    temporary_legend_info = [];
    let hidden_company_name, legend_info_obj_of_hidden_marker;
    this.props.data.company_names.map((company, index) => {
      marker_color.push(company);
      marker_color.push(Marker_COLORS[index]);
    });
    /**
   * This loop populates a list with marker objects. If a particular company name is present
      in hiddenCompanyName list then coresponding object in this.state.legendInfo with same company
       name will be pushed in the temporary_legendInfo list. This will prevent setting default status to
       true to already hidden company names.
   */
    for (let j = 0; j < ticker_list.length; j++) {
      hidden_company_name = this.state.hiddenCompanyName.find(
        (ele) => ele === ticker_list[j]
      );

      if (hidden_company_name) {
        legend_info_obj_of_hidden_marker = this.state.legendInfo.find(
          (ele, index) => ele.value[0] === hidden_company_name
        );
        temporary_legend_info.push(
          this.state.legendInfo[
            this.state.legendInfo.indexOf(legend_info_obj_of_hidden_marker)
          ]
        );
      } else {
        temporary_legend_info.push({
          key: j,
          value: [ticker_list[j], Marker_COLORS[j]],
          status: true,
        });
      }
    }
    this.setState({ legendInfo: temporary_legend_info });
    // default color in case no match is found
    marker_color.push("#FF0000");
    return marker_color;
  };

  /**
   * Function gets triggred when a particular legend item is clicked. This function is responsible
   * for marker toggeling. If status of particular marker is true then it toggled to false and vice versa.
   * According to  the status value it triggers  dedicated toggeling functions i.e. onMarkerShow and onMarkerhide.
   * legendInfo state is not updated with in this function to bundle setstate.
   * JSON.parse() is used for deep copying.
   */
  onMarkerToggle = (companyName, id) => {
    this.loadingSpinner(true);

    let duplicate_legend = JSON.parse(JSON.stringify(this.state.legendInfo));
    duplicate_legend[id].status = !duplicate_legend[id].status;
    if (duplicate_legend[id].status === true) {
      this.onMarkerShow(companyName, duplicate_legend);
    } else {
      this.onMarkerHide(companyName, duplicate_legend);
    }
  };
  /**
   *  This function is called by onMarkerToggle(). Function will remove already existing company name from state's hiddenCompanyName
   * @param {string} companyName: name of the company on which toggle operation is to be executed
   * @param {List of objects} duplicate_legend: after making changes in onMarker the duplicate legend
   * indo is sent as an parameter to this funciton so that legendInfo and hiddenCompanyName could be
   * update together. this will save calling setstate multiple times.
   */
  onMarkerShow = (companyName, duplicate_legend) => {
    let duplicate_hidden_tickers = JSON.parse(
      JSON.stringify(this.state.hiddenCompanyName)
    );
    let updated_ticker_display_list = duplicate_hidden_tickers.filter(
      (ele) => ele !== companyName
    );
    let dummy_visible_markers = this.getVisibleMarkers(duplicate_legend);

    this.filterSource(dummy_visible_markers);

    this.setState({
      hiddenCompanyName: updated_ticker_display_list,
      legendInfo: duplicate_legend,
      is_loading: null,
    });
  };
  /**
   *  This function is called by onMarkerToggle(). Function will add company name to state's hiddenCompanyName
   * @param {string} companyName: name of the company on which toggle operation is to be executed
   * @param {List of objects} duplicate_legend: after making changes in onMarker the duplicate legend
   * indo is sent as an parameter to this funciton so that legendInfo and hiddenCompanyName could be
   * update together. this will save calling setstate multiple times.
   */
  onMarkerHide = (companyName, duplicate_legend) => {
    let duplicate_hidden_tickers = JSON.parse(
      JSON.stringify(this.state.hiddenCompanyName)
    );
    duplicate_hidden_tickers.push(companyName);
    this.filterSource(this.getVisibleMarkers(duplicate_legend));

    this.setState({
      hiddenCompanyName: duplicate_hidden_tickers,
      legendInfo: duplicate_legend,
      is_loading: null,
    });
  };
  filterSource = (visible_markers) => {
    if (this.map.getLayer("points-layer") !== "undefined") {
      this.map.removeLayer("points-layer");
    }
    let marker_color_expression = this.colorPickerForMarker();
    this.map.addLayer({
      id: "points-layer",
      type: "circle",
      source: "points-src",
      paint: {
        // make circles larger as the user zooms from z12 to z22
        "circle-radius": {
          base: 1.75,
          stops: [
            [17, 4],
            [22, 180],
          ],
        },
        "circle-color": marker_color_expression,
      },
    });
    let marker_filter = [
      "match",
      ["get", "companyName"],
      visible_markers,
      true,
      false,
    ];
    this.map.setFilter("points-layer", marker_filter);
    this.map.on("render", this.stopSpinner);
  };
  getVisibleMarkers = (marker_legend) => {
    let visible_markers = [];
    marker_legend.map((ele) => {
      if (ele.status) {
        visible_markers.push(ele.value[0]);
      }
    });
    if (visible_markers.length === 1) {
      return visible_markers[0];
    }
    return visible_markers;
  };

  memorySizeOf = (obj) => {
    var bytes = 0;

    function sizeOf(obj) {
      if (obj !== null && obj !== undefined) {
        switch (typeof obj) {
          case "number":
            bytes += 8;
            break;
          case "string":
            bytes += obj.length * 2;
            break;
          case "boolean":
            bytes += 4;
            break;
          case "object":
            var objClass = Object.prototype.toString.call(obj).slice(8, -1);
            if (objClass === "Object" || objClass === "Array") {
              for (var key in obj) {
                if (!obj.hasOwnProperty(key)) continue;
                sizeOf(obj[key]);
              }
            } else bytes += obj.toString().length * 2;
            break;
        }
      }
      return bytes;
    }

    function formatByteSize(bytes) {
      if (bytes < 1024) return bytes + " bytes";
      else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + " KiB";
      else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + " MiB";
      else return (bytes / 1073741824).toFixed(3) + " GiB";
    }

    return formatByteSize(sizeOf(obj));
  };
  render() {
    let mapbox_id = this.randomId ? this.randomId : "mapContainer";

    return (
      <div className="interactiveMap-container">
        <div>
          <h4 style={{ fontSize: "16px", margin: "0px" }}>
            {this.props.data.map_title
              ? this.props.data.map_title
              : "Mapbox Map"}
          </h4>
          <div id="Legend">
            {this.state.legendInfo.map((ele, index) => {
              return (
                <LegendInfo
                  index={ele.key}
                  key={ele.key}
                  onMarkerToggle={this.onMarkerToggle}
                  onMarkerShow={this.onMarkerShow}
                  onMarkerHide={this.onMarkerHide}
                  companyName={ele.value[0]}
                  markerColor={ele.value[1]}
                  status={ele.status}
                />
              );
            })}
          </div>
        </div>
        <div className="map-main-container">
          <MapContainer className="MapView" id={mapbox_id}>
            {this.state.is_loading && (
              <div id="loading-background">
                <CircularProgress color="secondary" />
              </div>
            )}
          </MapContainer>
          <div>
            <a target="_blank" href={CRUSTDATA_PLATFORM_URL}>
              Source: Crustdata Alternative Data
            </a>
          </div>
        </div>
      </div>
    );
  }
}
